import React from "react";
import { withTranslation } from "react-i18next";
import Loader from "../loader/Loader";
import Modal from "./Modal";
import { TextField } from "@mui/material";
import parse from "html-react-parser";

class DeleteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
    };
    this.modalRef = React.createRef();
    this.onInputChange = this.onInputChange.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.isButtonEnabled = this.isButtonEnabled.bind(this);
  }

  onCancelClick() {
    if (this.modalRef && this.modalRef.current) {
      this.modalRef.current.onModalCloseClick();
    }
  }

  onInputChange(e) {
    this.setState({
      inputValue: e.target.value,
    });
  }

  isButtonEnabled() {
    const { itemValue, emailAddress } = this.props;

    if (itemValue) {
      const cleanedItemVaue = itemValue
        .normalize("NFC") // Normalisation Unicode pour uniformiser les caractères spéciaux
        .trim() // Supprime les espaces en début et fin
        .replace(/ +/g, " ") // Remplace les espaces multiples par un seul
        .replace(/\u00a0/g, " ") // Remplace les espaces insécables par des espaces standards
        .replace(/\s+/g, " ") // Remplace tout type d'espace par un espace unique
        .replace("\u00a0", " "); // Remplace les saut de ligne par un espace unique
      const cleanedInputVaue = this.state.inputValue
        .normalize("NFC") // Normalisation Unicode pour uniformiser les caractères spéciaux
        .trim() // Supprime les espaces en début et fin
        .replace(/ +/g, " ") // Remplace les espaces multiples par un seul
        .replace(/\u00a0/g, " ") // Remplace les espaces insécables par des espaces standards
        .replace(/\s+/g, " ") // Remplace tout type d'espace par un espace unique
        .replace("\u00a0", " "); // Remplace les saut de ligne par un espace unique

      return itemValue && cleanedItemVaue === cleanedInputVaue;
    }
    if (emailAddress) {
      return emailAddress === this.state.inputValue;
    }
  }

  render() {
    const {
      t,
      itemValue,
      title,
      subtitle,
      subtitle1,
      subtitle2,
      emailAddress,
      inputLabel,
      buttonLabel,
      deleteAccount,
    } = this.props;
    const buttonIsEnabled = this.isButtonEnabled();
    let btnClassName = "";
    deleteAccount
      ? (btnClassName =
          "button backgroundless margin-left-5 color-e04f77 font-weight-600")
      : (btnClassName = "button backgroundless margin-left-5");
    return (
      <Modal
        ref={this.modalRef}
        display={this.props.display}
        onClose={this.props.onClose}
      >
        <div className="font-size-30 font-inter-700 color-000 padding-bottom-16">
          {title}
        </div>
        {subtitle && (
          <div className="font-size-15 font-inter-400 color-7f7f7f">
            {subtitle}:
          </div>
        )}
        {subtitle1 && subtitle2 && emailAddress && (
          <div className="font-size-15 font-inter-400 color-7f7f7f">
            <span>{subtitle1}</span>
            <span className="font-weight-600">{emailAddress}</span>
            <span>{subtitle2}</span>
          </div>
        )}
        <div className="font-size-15 font-inter-600 color-000 padding-bottom-36 text-break-line">
          {itemValue ? itemValue : ""}
        </div>
        <form>
          <div className="padding-bottom-36">
            <label className="display-block color-969696 font-size-15 font-inter-600 padding-bottom-4">
              {inputLabel}
            </label>
            <TextField
              disabled={this.props.loading}
              onChange={this.onInputChange}
              sx={{ width: "100%" }}
            />
          </div>
          <div className="overflow-hidden">
            {!this.props.loading && (
              <div className="float-right">
                <button
                  className="button backgroundless"
                  type="button"
                  onClick={(e) => {
                    this.onCancelClick(e);
                  }}
                >
                  {t("Cancel")}
                </button>
                <button
                  className={btnClassName}
                  disabled={!buttonIsEnabled}
                  onClick={this.props.onDeleteItem}
                >
                  {buttonLabel}
                </button>
              </div>
            )}
            {this.props.loading && (
              <div className="float-right" style={{ padding: "2px 0" }}>
                <Loader />
              </div>
            )}
          </div>
        </form>
      </Modal>
    );
  }
}

export default withTranslation()(DeleteModal);
