import StringHelper from "../helpers/StringHelper";

export const ACCOUNT_PLAN = {
  FREE: "Free",
  PRO: "Pro",
  BUSINESS: "Business",
  ENTERPRISE: "Enterprise",
  ENTERPRISE_SAAS: "Enterprise SaaS",
  MANUAL_BILLING: "Manual Billing",
};

export const STATUS_USER_ACCOUNT = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

export const ACCOUNT_PLAN_FREQUENCY = {
  YEARLY: "YEARLY",
  MONTHLY: "MONTHLY",
};

export const getAccountPlanFrequency = (planFreq) => {
  return StringHelper.capitalize(planFreq.toLowerCase());
};

export const getAccountPlan = (planKey) => {
  if (!planKey) return ACCOUNT_PLAN.FREE;
  planKey = StringHelper.capitalize(planKey.toLowerCase());
  if (isAccountPlanValid(planKey)) {
    return planKey;
  }
  return null;
};

export const getAccountNextPlan = (planKey) => {
  const currentPlan = getAccountPlan(planKey);
  switch (currentPlan) {
    case ACCOUNT_PLAN.FREE:
      return ACCOUNT_PLAN.PRO;
    case ACCOUNT_PLAN.PRO:
      return ACCOUNT_PLAN.BUSINESS;
    case ACCOUNT_PLAN.BUSINESS:
      return ACCOUNT_PLAN.ENTERPRISE;
    case ACCOUNT_PLAN.ENTERPRISE:
      return ACCOUNT_PLAN.ENTERPRISE;
    default:
      return ACCOUNT_PLAN.PRO;
  }
};

export const getAllAccountPlans = () => {
  return [
    ACCOUNT_PLAN.FREE,
    ACCOUNT_PLAN.PRO,
    ACCOUNT_PLAN.BUSINESS,
    ACCOUNT_PLAN.ENTERPRISE,
  ];
};

export const isAccountPlanValid = (plan) => {
  return getAllAccountPlans().includes(plan);
};

export const isValidUpgradePlan = (account, targetPlan) => {
  const currentPlan = getAccountPlan(account.plan_key);
  const upgradePlan = getAccountPlan(targetPlan);

  // Account is downgraded to FREE
  if (
    account.stripe_subscription_id === null &&
    account.expiration_date !== null
  ) {
    return true;
  }

  switch (currentPlan) {
    case ACCOUNT_PLAN.FREE:
      return true;
    case ACCOUNT_PLAN.PRO:
      return (
        upgradePlan == ACCOUNT_PLAN.PRO ||
        upgradePlan == ACCOUNT_PLAN.BUSINESS ||
        upgradePlan == ACCOUNT_PLAN.ENTERPRISE
      );
    case ACCOUNT_PLAN.BUSINESS:
      return (
        upgradePlan == ACCOUNT_PLAN.BUSINESS ||
        upgradePlan == ACCOUNT_PLAN.ENTERPRISE
      );
    case ACCOUNT_PLAN.ENTERPRISE:
      return upgradePlan == ACCOUNT_PLAN.ENTERPRISE;
    default:
      return false;
  }
};

export const getAdvantagesByPlan = (t, plan) => {
  const currentPlan = getAccountPlan(plan).toLowerCase();
  const advantages = getPlanAdvantages(t);
  if (advantages[currentPlan] !== undefined) {
    return advantages[currentPlan];
  }
  return null;
};

export const getDowngradeAdvantagesList = (t) => {
  return [
    "Up to 10 projects",
    "Customize integrated classes",
    "Up to 4 custom classes",
    "Reporting and Insights",
    "Exports",
    "Unlimited users",
    "Unlimited Youtube videos",
    "Mixed language support",
  ];
};

export const getPlanAdvantages = (t) => {
  return {
    free: {
      title: t("FREE"),
      planType: ACCOUNT_PLAN.FREE,
      subtitle: t("For individuals"),
      monthlyPrice: 0,
      yearlyMonthlyPrice: 0,
      price: t("/forever"),
      subpriceYearly: "",
      subprice: "",
      rawClass: [],
      advantages: [
        {
          title: t("With Free plan, you can get"),
          advantage: [
            t("Unlimited Project"),
            t("All Features"),
            t("1 AI Category "),
          ],
        },
        {
          title: t("Review collection"),
          advantage: [t("G shopping"), t("> 100 languages")],
        },
        {
          title: t("Review storage"),
          advantage: [t("500 included")],
        },
      ],
    },
    pro: {
      title: t("PRO"),
      planType: ACCOUNT_PLAN.PRO,
      subtitle: t("For professionals ready to unlock AI"),
      subprice: t(" when billed monthly"),
      subpriceYearly: t(" when billed yearly"),
      freeCredit: 1000,
      rawClass: [],
      advantages: [
        {
          title: t("With Pro plan, you can get"),
          advantage: [
            t("Unlimited Project"),
            t("All Features"),
            t("1 AI Category"),
            t("Additional Category (in option)"),
            t("1 AI Customization"),
            t("Export & Sharing"),
          ],
        },
        {
          title: t("Review collection"),
          advantage: [t("G shopping, Amazon, Youtube"), t("> 100 languages")],
        },
        {
          title: t("Review storage"),
          advantage: [t("1 000 included"), t("Additional Storage (in option)")],
        },
        {
          title: t("Support"),
          advantage: [t("Chat")],
        },
      ],
    },
    business: {
      title: t("BUSINESS"),
      planType: ACCOUNT_PLAN.BUSINESS,
      subtitle: t("For SMBs needing in-depth analysis"),
      subprice: t(" when billed monthly"),
      subpriceYearly: t(" when billed yearly"),
      freeCredit: 5000,
      rawClass: [],
      advantages: [
        {
          title: t("With Business plan, you can get"),
          advantage: [
            t("Unlimited Project"),
            t("All Features"),
            t("1 AI Category "),
            t("Additional Category (in option)"),
            t("4 AI Customization"),
            t("Export & Sharing"),
          ],
        },
        {
          title: t("Review collection"),
          advantage: [t("All"), t("> 100 languages")],
        },
        {
          title: t("Review storage"),
          advantage: [t("2 400 included"), t("Additional Storage (in option)")],
        },
        {
          title: t("Support"),
          advantage: [t("Chat")],
        },
      ],
    },
    enterprise: {
      title: t("ENTERPRISE"),
      planType: ACCOUNT_PLAN.ENTERPRISE_SAAS,
      subtitle: t("For large enterprises with custom needs"),
      subprice: t(" when billed monthly"),
      subpriceYearly: t(" when billed yearly"),
      freeCredit: 20000,
      rawClass: [],
      advantages: [
        {
          title: t("With Enterprise plan, you can get"),
          advantage: [
            t("Unlimited Project"),
            t("All Features"),
            t("1 AI Category "),
            t("Additional Category (in option)"),
            t("Unlimited AI Customization"),
            t("Bulk Import"),
            t("Export & Sharing"),
          ],
        },
        {
          title: t("Review collection"),
          advantage: [t("All + Custom Connector"), t("> 100 languages")],
        },
        {
          title: t("Review storage"),
          advantage: [t("Custom Storage"), t("Additional Storage (in option)")],
        },
        {
          title: t("Support"),
          advantage: [t("Premium")],
        },
      ],
    },
  };
};
