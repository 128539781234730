import React, { useEffect } from "react";
import { Layout, Menu, MenuItemLink, useStore } from "react-admin";

import UserIcon from "@mui/icons-material/Group";
import FolderIcon from "@mui/icons-material/Folder";
import BusinessIcon from "@mui/icons-material/Business";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import UpdateIcon from "@mui/icons-material/Update";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StorageIcon from "@mui/icons-material/Storage";
import SettingsIcon from "@mui/icons-material/Settings";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ArticleIcon from "@mui/icons-material/Article";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import BuildIcon from "@mui/icons-material/Build";

import "../../../res/style/admin.css";

import { Divider, useMediaQuery } from "@mui/material";
import SubMenu from "./SubMenu";

export const AdminCustomMenu = ({ onMenuClick }) => {
  return (
    <Menu>
      <MenuItemLink
        to={"/"}
        primaryText={"Dashboard"}
        leftIcon={<DashboardIcon />}
        onClick={onMenuClick}
      />
      <SubMenu primaryText="Customer" leftIcon={<UserIcon />}>
        <MenuItemLink
          to={"/users"}
          primaryText={"Users"}
          leftIcon={<UserIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to={"/accounts"}
          primaryText={"Company"}
          leftIcon={<BusinessIcon />}
          onClick={onMenuClick}
        />
      </SubMenu>
      <SubMenu primaryText="Administration" leftIcon={<SettingsIcon />}>
        <MenuItemLink
          to={"/services"}
          primaryText={"Services"}
          leftIcon={<StorageIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to={"/reports"}
          primaryText={"Reports"}
          leftIcon={<SummarizeIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to={"/classes"}
          primaryText={"Classes"}
          leftIcon={<ArticleIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to={"/usecases"}
          primaryText={"Use cases"}
          leftIcon={<ContentPasteSearchIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to="/prompt-test/create"
          primaryText="Prompt test"
          leftIcon={<BuildIcon />}
          onClick={onMenuClick}
        />
      </SubMenu>
      <SubMenu primaryText="Data analytics" leftIcon={<AnalyticsIcon />}>
        <MenuItemLink
          to={"/projects"}
          primaryText={"Projects"}
          leftIcon={<FolderIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to={"/prompts"}
          primaryText={"Prompts"}
          leftIcon={<BuildIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to={"/data-sources"}
          primaryText={"Datasources"}
          leftIcon={<PermMediaIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to={"updated-score"}
          primaryText={"Updated score"}
          leftIcon={<UpdateIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to={"/analytics"}
          primaryText={"Analytics"}
          leftIcon={<AnalyticsIcon />}
          onClick={onMenuClick}
        />
      </SubMenu>

      <div className="padding-10">
        <Divider />
      </div>
      {/* Custom links here */}
      <MenuItemLink
        to="#"
        primaryText="Stripe"
        leftIcon={<CreditCardIcon />}
        onClick={() =>
          window.open(
            "https://dashboard.stripe.com/test/dashboard",
            "_blank",
            "noopener"
          )
        }
      />
    </Menu>
  );
};

export const AdminCustomAppBar = ({
  children,
  classes,
  className,
  logo,
  logout,
  open,
  title,
  toggleSidebar,
  userMenu,
  width,
  ...rest
}) => null;

export const AdminCustomLayout = ({ appBar, menu, ...props }) => {
  const isLargeScreen = useMediaQuery("(min-width:600px)"); // Detect large screens
  // Optionally override the sidebar state in the store
  const [sidebarOpen, setSidebarOpen] = useStore("sidebar.open", true);

  useEffect(() => {
    if (isLargeScreen) {
      setSidebarOpen(true); // Open the sidebar on large screens
    }
  }, [isLargeScreen, sidebarOpen, setSidebarOpen]);

  return (
    <Layout
      {...props}
      className="admin-custom-layout"
      menu={AdminCustomMenu}
      appBar={AdminCustomAppBar}
    />
  );
};
