import { Box, Hidden, Typography } from "@mui/material";
import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  TextInput,
  ReferenceInput,
  BooleanInput,
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  SearchInput,
  BooleanField,
  Edit,
  SimpleForm,
  TopToolbar,
  Create,
  TabbedForm,
  EditButton,
  ListButton,
  Button,
  DeleteButton,
  useRecordContext,
  useNotify,
  useRedirect,
  AutocompleteInput,
  Toolbar,
  SaveButton,
  NumberInput,
  Link,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  required,
} from "react-admin";
import AddIcon from "@mui/icons-material/Add";
import { getUrlParameters } from "./SectionList";
import { BackButton } from "./elements/CustomFields";

const postFilters = [
  <SearchInput source="q" className="remove-line-height" alwaysOn />,
  <BooleanInput label="Enabled" source="is_enabled" />,
  <SelectArrayInput label="Visibility" source="visibility" choices={[
    { id: "PUBLIC", name: "Public" },
    { id: "RESTRICTED", name: "Restricted" }]}/>
];

const UseCaseShowButton = ({ record }) => [
  <EditButton label="Edit" record={record} />,
  <ShowButton label="Show" record={record} />,
];

export const UseCaseList = (props) => (
  <List filters={postFilters} {...props} perPage={25}>
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <TextField label="UseCase ID" source="id" />
      <TextField label="Name" source="name" />
      <TextField label="Display Order" source="sort_order" />
      <BooleanField label="Enable" source="is_enabled" />
      <TextField label="Visibility" source="visibility" />
      <TextField label="Updated at" source="updated_at" />
      <UseCaseShowButton />
    </Datagrid>
  </List>
);

const UseCaseShowActions = () => (
  <TopToolbar>
    <EditButton />
    {/* Add your custom actions */}
    <ListButton />
  </TopToolbar>
);

export const UseCaseShow = (props) => (
  <Show actions={<UseCaseShowActions />}>
    <SimpleShowLayout>
      <TextField label="UseCase ID" source="id" />
      <TextField label="Name" source="name" />
      <TextField label="Description" source="description" />
      <TextField label="Display Order" source="sort_order" />
      <BooleanField label="Enable" source="is_enabled" />
      <TextField label="Visibility" source="visibility" />
      <TextField label="Updated at" source="updated_at" />
      <TextField label="Created at" source="created_at" />
    </SimpleShowLayout>
  </Show>
);

const UseCaseEditActions = () => (
  <TopToolbar>
    <ShowButton />
    {/* Add your custom actions */}
    <ListButton />
  </TopToolbar>
);

const PostEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);


const UseCasereportsRowClick = (id, resource, record) => {
  return `/reports/${record.gptreport_id}/show`;
};

export const UseCaseCreate = () => (
  <Create resource="usecases">
    <BackButton to={"/usecases"} />
    <SimpleForm>
      <TextInput label="Name" source="name" validate={[required()]} />
      <TextInput label="Description" multiline={true}  source="description" validate={[required()]} />
      <TextInput label="Display Order" source="sort_order" />
      <SelectArrayInput label="Visibility" source="visibility" choices={[
        { id: "PUBLIC", name: "Public" },
        { id: "RESTRICTED", name: "Restricted" }]}/>
      <BooleanInput label="Enable" source="is_enabled" />
    </SimpleForm>
  </Create>
);

export const UseCaseEdit = (props) => (
  <Edit mutationMode="pessimistic" actions={<UseCaseEditActions />}>
    <TabbedForm warnWhenUnsavedChanges toolbar={<PostEditToolbar />}>
      <TabbedForm.Tab label="General">
        <TextInput disabled label="UseCase ID" source="id" />
        <TextInput label="Name" source="name" />
        <TextInput label="Description" source="description" fullWidth />
        <NumberInput label="Display Order" source="sort_order" />
        <SelectInput label="Visibility" source="visibility" choices={[
        { id: "PUBLIC", name: "Public" },
        { id: "RESTRICTED", name: "Restricted" }]}/>
        <BooleanInput
          label="Enable"
          source="is_enabled"
          helperText="The UseCase can be selected by the user"
        />
        <TextInput disabled label="Updated at" source="updated_at" />
        <TextInput disabled label="Created at" source="created_at" />
      </TabbedForm.Tab>
      <TabbedForm.Tab label="Reports" path="reports">
        <ReferenceManyField
          reference="usecase-reports"
          target="usecase_id"
          label="Reports"
        >
          <Datagrid
            rowClick={UseCasereportsRowClick}
            bulkActionButtons={false}
          >
            <TextField label="ID" source="id" />
            <TextField label="Report ID" source="gptreport_id" />
            <TextField label="Name" source="report_name" sortable={false}/>
            <TextField label="Type" source="scope_type" sortable={false}/>
            <BooleanField label="Enabled" source="enabled" />
            <TextField label="Order" source="sort_order" />
            <DeleteButton redirect="" />
          </Datagrid>
        </ReferenceManyField>
        <CreateRelatedReportButton />
      </TabbedForm.Tab>
      <TabbedForm.Tab label="Access" path="access">
      <ReferenceManyField
          reference="usecase-access"
          target="id"
          label="Access"
        >
          <Datagrid
            rowClick={UseCasereportsRowClick}
            bulkActionButtons={false}
          >
            <TextField label="ID" source="id" />
            <TextField label="Account ID" source="account_id" />
            <TextField label="Account Name" source="account_name" sortable={false} />
            <BooleanField label="Access Granted" source="access_granted" />
            <DeleteButton redirect="" />
          </Datagrid>
        </ReferenceManyField>
        <CreateRelatedAccessButton />
      </TabbedForm.Tab>
    </TabbedForm>
  </Edit>
);

export const CreateRelatedReportButton = () => {
  const usecase = useRecordContext();
  return (
    <Button
      component={Link}
      to={`/usecase-reports/create?usecase_id=${usecase.id}`}
      color="primary"
      label="Create"
    >
      <AddIcon />
    </Button>
  );
};


export const CreateRelatedAccessButton = () => {
  const usecase = useRecordContext();
  return (
    <Button
      component={Link}
      to={`/usecase-access/create?usecase_id=${usecase.id}`}
      color="primary"
      label="Add Access"
    >
      <AddIcon />
    </Button>
  );
};


// Note we declared the function outside the component to avoid rerenders

const PostCreateActions = () => {
  const redirect = useRedirect();
  const urlParams = getUrlParameters();
  const UseCaseId = urlParams["usecase_id"];
  const handleCancel = () => {
    redirect(`/usecases/${UseCaseId}/reports`);
  };

  return (
    <TopToolbar>
      <Button color="primary" onClick={handleCancel}>Cancel</Button>
    </TopToolbar>
  );
};

const optionRenderer = (choice) => `${choice.id} - ${choice.title} [${choice.scope_type}] - Enabled: ${choice.is_enabled}`;

export const UsecaseReportCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const urlParams = getUrlParameters();
  const UseCaseId = urlParams["usecase_id"];

  const onSuccess = () => {
    console.log("onSuccess UseCaseId",UseCaseId);
    // display a notification to confirm the creation
    notify("ra.notification.created");
    redirect(`/usecases/${UseCaseId}/reports`);
  };

  return (
    <Create actions={<PostCreateActions />} mutationOptions={{ onSuccess, meta: { UseCaseId: UseCaseId } } }>
      <SimpleForm>
        <Hidden source="usecase_id" value="{UseCaseId}" />
        <ReferenceInput
          label="Report"
          source="gptreport_id"
          reference="reports"
          sort="ASC"
        >
          <AutocompleteInput label="Report" optionText={optionRenderer} optionValue="id" validate={[required()]} fullWidth={true}/>
        </ReferenceInput>
        <TextInput label="Order" source="sort_order" validate={[required()]} />
      </SimpleForm>
    </Create>
  );
};

const PostCreateUsecaseActions = () => {
  const redirect = useRedirect();
  const urlParams = getUrlParameters();
  const UseCaseId = urlParams["usecase_id"];
  const handleCancel = () => {
    redirect(`/usecases/${UseCaseId}/access`);
  };

  return (
    <TopToolbar>
      <Button color="primary" onClick={handleCancel}>Cancel</Button>
    </TopToolbar>
  );
};


const accountRenderer = (choice) => `${choice.id} - ${choice.account_name}`;

export const UsecaseAccessCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const urlParams = getUrlParameters();
  const UseCaseId = urlParams["usecase_id"];

  const onSuccess = () => {
    console.log("onSuccess UseCaseId",UseCaseId);
    // display a notification to confirm the creation
    notify("ra.notification.created");
    redirect(`/usecases/${UseCaseId}/access`);
  };

  return (
    <Create actions={<PostCreateUsecaseActions />}  mutationOptions={{ onSuccess, meta: { UseCaseId: UseCaseId } }}>
      <SimpleForm>
        <Hidden source="usecase_id" value="{UseCaseId}" />
        <ReferenceInput
          label="Account"
          source="account_id"
          reference="accounts"
          sort="ASC"
        >
          <AutocompleteInput optionText={accountRenderer} optionValue="id" validate={[required()]} fullWidth={true}/>
        </ReferenceInput>
        <BooleanInput label="Access Granted" source="access_granted" />
      </SimpleForm>
    </Create>
  );
};
