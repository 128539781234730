import { stringify } from "query-string";
import HttpHelper from "../../../app/helpers/HttpHelper";

const authFetch = (url) => {
  const requestOptions = HttpHelper.getAuthRequestOptions("GET");
  return fetch(url, requestOptions)
    .then(HttpHelper.handleLoggedResponse)
    .then(
      (response) => {
        const data = {
          data: response["data"],
        };
        if (response["meta"]) {
          data["total"] = response["meta"]["total"];
        }
        return data;
      },
      (error) => {
        console.log(error);
        return {};
      }
    )
    .catch((error) => {
      console.log(error);
      return {};
    });
};

const postFetch = (url, params, method = "POST") => {
  const requestOptions = HttpHelper.getAuthRequestOptions(method, params.data, {
    "Content-Type": "application/json",
  });
  return fetch(url, requestOptions)
    .then(HttpHelper.handleLoggedResponse)
    .then(
      (response) => {
        return { data: response };
      },
      (error) => {
        console.log(error);
        return {};
      }
    )
    .catch((error) => {
      console.log(error);
      return {};
    });
};

const GET_USERS = "/api/v1/backoffice-user/get-users";
const GET_USER = "/api/v1/backoffice-user/get-user";
const GET_ACCOUNTS = "/api/v1/backoffice-account/get-accounts";
const GET_KPI = "/api/v1/backoffice-account/get-kpi-accounts-data";
const GET_USER_ACCOUNT = "/api/v1/backoffice-account/get-user-accounts";
const GET_ACCOUNT_USER = "/api/v1/backoffice-account/get-account-users";
const GET_ACCOUNT = "/api/v1/backoffice-account/get-account";
const UPDATE_ACCOUNT = "/api/v1/backoffice-account/edit-account";
const GET_PROJECTS = "/api/v1/backoffice-project/get-projects";
const GET_PROJECT = "/api/v1/backoffice-project/get-project";
const GET_MEDIAS = "/api/v1/backoffice-media/get-medias";
const GET_MEDIA = "/api/v1/backoffice-media/get-media";
const GET_UPDATED_SCORES = "/api/v1/backoffice-caption-updated-score";
const GET_ANALYTICS = "/api/v1/backoffice-analytics/get-analytics";
const GET_REPORT = "/api/v1/backoffice-gptreport/report";
const GET_REPORTS = "/api/v1/backoffice-gptreport/reports";
const GET_SECTION = "/api/v1/backoffice-gptreport/section";
const GET_SERVICES = "/api/v1/backoffice-services/services";
const GET_CATEGORIES = "/api/v1/backoffice-categories/product_categories";
const UPDATE_ACCOUNT_CATEGORY =
  "/api/v1/backoffice-categories/update-account-product-category";
const GET_FEATURE_LIST = "/api/v1/backoffice-gptreport/feature_list";
const GET_CLASSE = "/api/v1/backoffice-classe/classe";
const GET_CLASSES = "/api/v1/backoffice-classe/classes";
const GET_TEMPLATES = "/api/v1/backoffice-gptreport/templates";
const GET_SLIDES = "/api/v1/backoffice-gptreport/slides";
const PROMPT = "/api/v1/backoffice-gptreport/prompt-test";
const GET_PROMPTS = "/api/v1/backoffice-gptreport/prompts";
const GET_USECASES = "/api/v1/backoffice/usecases";
const GET_USECASE_REPORTS = "/api/v1/backoffice/usecase-reports";
const GET_USECASE_ACCESS = "/api/v1/backoffice/usecase-access";

const dataProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: field,
      sortOrder: order,
      page: page,
      perPage: perPage,
      filter: JSON.stringify(params.filter),
    };

    switch (resource) {
      case "users":
        const apiUrl = HttpHelper.getApiUrl(GET_USERS);
        const url = `${apiUrl}?${stringify(query)}`;
        return authFetch(url);
      case "reports":
        const reportApiUrl = HttpHelper.getApiUrl(GET_REPORTS);
        const reportUrl = `${reportApiUrl}?${stringify(query)}`;
        return authFetch(reportUrl);
      case "accounts":
        const accountApiUrl = HttpHelper.getApiUrl(GET_ACCOUNTS);
        const accountUrl = `${accountApiUrl}?${stringify(query)}`;
        return authFetch(accountUrl);
      case "analytics":
        const KPIApiUrl = HttpHelper.getApiUrl(GET_KPI);
        const KPIUrl = `${KPIApiUrl}?${stringify(query)}`;
        return authFetch(KPIUrl);
      case "projects":
        const projectApiUrl = HttpHelper.getApiUrl(GET_PROJECTS);
        const projectUrl = `${projectApiUrl}?${stringify(query)}`;
        return authFetch(projectUrl);
      case "data-sources":
        const mediaApiUrl = HttpHelper.getApiUrl(GET_MEDIAS);
        const mediaUrl = `${mediaApiUrl}?${stringify(query)}`;
        return authFetch(mediaUrl);
      case "updated-score":
        const updatedScoreApiUrl = HttpHelper.getApiUrl(GET_UPDATED_SCORES);
        const updatedScoreUrl = `${updatedScoreApiUrl}?${stringify(query)}`;
        return authFetch(updatedScoreUrl);
      case "sections":
        const sectionsApiUrl = HttpHelper.getApiUrl(GET_REPORTS);
        const sectionsUrl =
          sectionsApiUrl + "/" + params.meta?.reportId + "/sections";
        const sectionsUrlQuery = `${sectionsUrl}?${stringify(query)}`;
        return authFetch(sectionsUrlQuery);
      case "services":
        const serviceApiUrl = HttpHelper.getApiUrl(GET_SERVICES);
        const serviceUrl = `${serviceApiUrl}?${stringify(query)}`;
        return authFetch(serviceUrl);
      case "categories":
        const categoriesApiUrl = HttpHelper.getApiUrl(GET_CATEGORIES);
        const categoriesUrl = `${categoriesApiUrl}?${stringify(query)}`;
        return authFetch(categoriesUrl);
      case "feature-list":
        const featureListApiUrl = HttpHelper.getApiUrl(GET_FEATURE_LIST);
        return authFetch(featureListApiUrl);
      case "classes":
        const classesApiUrl = HttpHelper.getApiUrl(GET_CLASSES);
        const classesUrl = `${classesApiUrl}?${stringify(query)}`;
        return authFetch(classesUrl);
      case "prompts":
        const promptApiUrl = HttpHelper.getApiUrl(GET_PROMPTS);
        const promptsUrl = `${promptApiUrl}?${stringify(query)}`;
        return authFetch(promptsUrl);             
      case "templates":
        const templatesUrl = HttpHelper.getApiUrl(GET_TEMPLATES);
        return authFetch(templatesUrl);
      case "slides":
        const slidesUrl = HttpHelper.getApiUrl(GET_SLIDES);
        return authFetch(slidesUrl);
      case "usecases":
        const usecasesUrl = HttpHelper.getApiUrl(GET_USECASES);
        const usecasesQuery = `${usecasesUrl}?${stringify(query)}`;
        return authFetch(usecasesQuery);
      default:
        return Promise.reject(`getList undefined for resource ${resource}`);
    }
  },

  getOne: (resource, params) => {
    switch (resource) {
      case "users":
        const userApiUrl = HttpHelper.getApiUrl(GET_USER);
        const userUrl = userApiUrl + "/" + params.id;
        return authFetch(userUrl);
      case "accounts":
        const accountApiUrl = HttpHelper.getApiUrl(GET_ACCOUNT);
        const accountUrl = accountApiUrl + "/" + params.id;
        return authFetch(accountUrl);
      case "projects":
        const projectApiUrl = HttpHelper.getApiUrl(GET_PROJECT);
        const projectUrl = projectApiUrl + "/" + params.id;
        return authFetch(projectUrl);
      case "data":
        const mediaApiUrl = HttpHelper.getApiUrl(GET_MEDIA);
        const mediaUrl = mediaApiUrl + "/" + params.id;
        return authFetch(mediaUrl);
      case "reports":
        const reportApiUrl = HttpHelper.getApiUrl(GET_REPORT);
        const reportUrl = reportApiUrl + "/" + params.id;
        return authFetch(reportUrl);
      case "sections":
        const sectionsApiUrl = HttpHelper.getApiUrl(GET_SECTION);
        const sectionsUrl = sectionsApiUrl + "/" + params.id;
        return authFetch(sectionsUrl);
      case "services":
        const serviceApiUrl = HttpHelper.getApiUrl(GET_SERVICES);
        const serviceUrl = serviceApiUrl + "/" + params.id;
        return authFetch(serviceUrl);
      case "classes":
        const classeApiUrl = HttpHelper.getApiUrl(GET_CLASSE);
        const classeUrl = classeApiUrl + "/" + params.id;
        return authFetch(classeUrl);
      case "prompts":
          const promptApiUrl = HttpHelper.getApiUrl(GET_PROMPTS);
          const promptUrl = promptApiUrl + "/" + params.id;
          return authFetch(promptUrl);        
      case "account-ia":
        const categoriesApiUrl = HttpHelper.getApiUrl(GET_CATEGORIES);
        const categoriesUrl =
          categoriesApiUrl +
          "/" +
          params.id +
          "/account/" +
          params.meta.accountId;
        return authFetch(categoriesUrl);
      case "usecases":
        const usecasesApiUrl = HttpHelper.getApiUrl(GET_USECASES);
        const usecasesUrl = usecasesApiUrl + "/" + params.id;
        return authFetch(usecasesUrl);
      default:
        return Promise.reject(`getOne undefined for resource ${resource}`);
    }
  },

  getMany: (resource, params) => {
    switch (resource) {
      case "accounts":
        return authFetch(HttpHelper.getApiUrl(GET_ACCOUNTS));
      case "users":
        break;
      case "categories":
        const categoriesApiUrl = HttpHelper.getApiUrl(GET_CATEGORIES);
        return authFetch(categoriesApiUrl);
      case "projects":
        const projectApiUrl = HttpHelper.getApiUrl(GET_PROJECTS);
        return authFetch(projectApiUrl);
      case "data-sources":
        const mediaApiUrl = HttpHelper.getApiUrl(GET_MEDIAS);
        return authFetch(mediaApiUrl);
      case "templates":
        const templatesUrl = HttpHelper.getApiUrl(GET_TEMPLATES);
        return authFetch(templatesUrl);
      case "slides":
        const slidesUrl = HttpHelper.getApiUrl(GET_SLIDES);
        return authFetch(slidesUrl);
      case "reports":      
        const reportApiUrl = HttpHelper.getApiUrl(GET_REPORTS);
        return authFetch(reportApiUrl);
      default:
        return Promise.reject(`getMany undefined for resource ${resource}`);
    }
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: field,
      sortOrder: order,
      page: page,
      perPage: perPage,
      filter: JSON.stringify(params.filter),
    };
    let query_str, url;

    switch (resource) {
      case "users":
        let userApiUrl = HttpHelper.getApiUrl(GET_ACCOUNT_USER);
        let userUrl = userApiUrl + "/" + params.id;
        url = `${userUrl}?${stringify(query)}`;
        return authFetch(url);
      case "accounts":
        const accountApiUrl = HttpHelper.getApiUrl(GET_USER_ACCOUNT);
        const accountUrl = accountApiUrl + "/" + params.id;
        const accountUrlQuery = `${accountUrl}?${stringify(query)}`;
        return authFetch(accountUrlQuery);
      case "analytics":
        const analyticsApiUrl = HttpHelper.getApiUrl(GET_ANALYTICS);
        const analyticsUrl = analyticsApiUrl + "/" + params.id;
        const analyticsUrlQuery = `${analyticsUrl}?${stringify(query)}`;
        return authFetch(analyticsUrlQuery);
      case "sections":
        const sectionsApiUrl = HttpHelper.getApiUrl(GET_REPORTS);
        const sectionsUrl = sectionsApiUrl + "/" + params.id + "/sections";
        const sectionsUrlQuery = `${sectionsUrl}?${stringify(query)}`;
        return authFetch(sectionsUrlQuery);
      case "service-accounts":
        url =
          HttpHelper.getApiUrl(GET_SERVICES) + "/" + params.id + "/accounts";
        query_str = `${url}?${stringify(query)}`;
        return authFetch(query_str);
      case "account-services":
        url =
          HttpHelper.getApiUrl(GET_USER_ACCOUNT) +
          "/" +
          params.id +
          "/services";
        query_str = `${url}?${stringify(query)}`;
        return authFetch(query_str);
      case "report-accounts":
        url = HttpHelper.getApiUrl(GET_REPORTS) + "/" + params.id + "/accounts";
        query_str = `${url}?${stringify(query)}`;
        return authFetch(query_str);
      case "account-reports":
        url =
          HttpHelper.getApiUrl(GET_USER_ACCOUNT) + "/" + params.id + "/reports";
        query_str = `${url}?${stringify(query)}`;
        return authFetch(query_str);
      case "account-usecases":
        url = HttpHelper.getApiUrl(GET_USER_ACCOUNT) + "/" + params.id + "/usecases";
        query_str = `${url}?${stringify(query)}`;
        return authFetch(query_str);        
      case "classe-accounts":
        url = HttpHelper.getApiUrl(GET_CLASSES) + "/" + params.id + "/accounts";
        query_str = `${url}?${stringify(query)}`;
        return authFetch(query_str);
      case "account-ia":
        const accountProductCategoriesApiUrl =
          HttpHelper.getApiUrl(GET_CATEGORIES) + "/account/" + params.id;
        return authFetch(accountProductCategoriesApiUrl);
      case "usecase-reports":
        const usecaseReportsApiUrl = HttpHelper.getApiUrl(GET_USECASES);
        const usecaseReportsUrl = usecaseReportsApiUrl + "/" + params.id + "/reports";
        query_str = `${usecaseReportsUrl}?${stringify(query)}`;
        return authFetch(query_str);
      case "usecase-access":
        const usecaseAccessApiUrl = HttpHelper.getApiUrl(GET_USECASES);
        const usecaseAccessUrl = usecaseAccessApiUrl + "/" + params.id + "/access";
        query_str = `${usecaseAccessUrl}?${stringify(query)}`;
        return authFetch(query_str);
      default:
        return Promise.reject(
          `getManyReference undefined for resource ${resource}`
        );
    }
  },

  update: (resource, params) => {
    switch (resource) {
      case "reports":
        const reportApiUrl = HttpHelper.getApiUrl(GET_REPORTS);
        const reportUrl = reportApiUrl + "/" + params.id;
        return postFetch(reportUrl, params, "PUT");
      case "sections":
        const rApiUrl = HttpHelper.getApiUrl(GET_REPORTS);
        const sectionsUrl =
          rApiUrl + "/" + params.meta?.reportId + "/sections/" + params.id;

        return postFetch(sectionsUrl, params, "PUT");
      case "services":
        const serviceApiUrl = HttpHelper.getApiUrl(GET_SERVICES);
        const serviceUrl = serviceApiUrl + "/" + params.id;
        return postFetch(serviceUrl, params, "PUT");
      case "classes":
        const classeApiUrl = HttpHelper.getApiUrl(GET_CLASSES);
        const classeUrl = classeApiUrl + "/" + params.id;
        return postFetch(classeUrl, params, "PUT");     
      case "account-ia":
        const args = Object.assign({}, params);
        args.data.account_id = params.meta.accountId;
        const updateAccountCategoryApiUrl = HttpHelper.getApiUrl(
          UPDATE_ACCOUNT_CATEGORY
        );
        const updateAccountCategoryUrl =
          updateAccountCategoryApiUrl + "/" + params.id;
        return postFetch(updateAccountCategoryUrl, args, "PUT");
      case "accounts":
        const updateAccountApiUrl = HttpHelper.getApiUrl(UPDATE_ACCOUNT);
        const updateAccountUrl = updateAccountApiUrl + "/" + params.id;
        return postFetch(updateAccountUrl, params, "PUT");
      case "usecases":
        const usecasesApiUrl = HttpHelper.getApiUrl(GET_USECASES);
        const usecasesUrl = usecasesApiUrl + "/" + params.id;
        return postFetch(usecasesUrl, params, "PUT");
      default:
        return Promise.reject(`Update undefined for resource ${resource}`);
    }
  },

  updateMany: (resource, params) => {
    return Promise.reject(`UpdateMany undefined for resource ${resource}`);
  },

  create: (resource, params) => {
    const data = params.data;
    switch (resource) {
      case "reports":
        const reportUrl = HttpHelper.getApiUrl(GET_REPORTS);
        return postFetch(reportUrl, params);
      case "report-clone":
        const reportCloneUrl = HttpHelper.getApiUrl(GET_REPORTS) + "/"+ params.meta.reportId + "/clone";
        return postFetch(reportCloneUrl, params);
      case "report-accounts":
        const reportApiUrl = HttpHelper.getApiUrl(GET_REPORTS);
        const reportAccountsUrl =
          reportApiUrl +
          "/" +
          params.meta.reportId +
          "/accounts/" +
          data.account_id;
        return postFetch(reportAccountsUrl, params);
      case "sections":
        const sectionAPIUrl = HttpHelper.getApiUrl(GET_REPORTS);
        const sectionURL =
          sectionAPIUrl + "/" + params.meta.reportId + "/sections";
        return postFetch(sectionURL, params);
      case "service-accounts":
        const serviceApiUrl = HttpHelper.getApiUrl(GET_SERVICES);
        const serviceUrl =
          serviceApiUrl +
          "/" +
          params.meta.serviceId +
          "/accounts/" +
          data.account_id;
        return postFetch(serviceUrl, params);
      case "classes":
        const classesApiUrl = HttpHelper.getApiUrl(GET_CLASSES);
        return postFetch(classesApiUrl, params);    
      case "classe-accounts":
        const classeApiUrl = HttpHelper.getApiUrl(GET_CLASSES);
        const classeAccountsUrl =
          classeApiUrl +
          "/" +
          params.meta.classeId +
          "/accounts/" +
          data.account_id;
        return postFetch(classeAccountsUrl, params);
      case "prompt-test":
        const promptTestUrl = HttpHelper.getApiUrl(PROMPT);
        return postFetch(promptTestUrl, params);
      case "usecases":
        const usecasesApiUrl = HttpHelper.getApiUrl(GET_USECASES);
        return postFetch(usecasesApiUrl, params);
      case "usecase-reports":
        let usecase_id = params.meta.UseCaseId;
        const usecaseReportsApiUrl = HttpHelper.getApiUrl(GET_USECASES);
        const usecaseReportsUrl = usecaseReportsApiUrl + "/" + usecase_id + "/reports";
        return postFetch(usecaseReportsUrl, params);
      case "usecase-access":
        let usecase_id2 = params.meta.UseCaseId;
        const usecaseAccessApiUrl = HttpHelper.getApiUrl(GET_USECASES);
        const usecaseAccessUrl = usecaseAccessApiUrl + "/" + usecase_id2 + "/access";
        return postFetch(usecaseAccessUrl, params);        
      default:
        return Promise.reject(`Create undefined for resource ${resource}`);
    }
  },

  delete: (resource, params) => {
    switch (resource) {
      case "reports":
        const reportApiUrl = HttpHelper.getApiUrl(GET_REPORTS);
        const reportUrl = reportApiUrl + "/" + params.id;
        return postFetch(reportUrl, params, "DELETE");
      case "report-accounts":
        let [report_account_id, report_id] = params.id.split("_");
        const reportAccountApiUrl = HttpHelper.getApiUrl(GET_REPORTS);
        const reportAccountUrl =
          reportAccountApiUrl +
          "/" +
          report_id +
          "/accounts/" +
          report_account_id;
        return postFetch(reportAccountUrl, params, "DELETE");
      case "sections":
        const rApiUrl = HttpHelper.getApiUrl(GET_REPORTS);
        const sectionsUrl =
          rApiUrl + "/" + params.meta?.reportId + "/sections/" + params.id;
        return postFetch(sectionsUrl, params, "DELETE");
      case "service-accounts":
        let [account_id, product_source_id] = params.id.split("_");
        const serviceApiUrl = HttpHelper.getApiUrl(GET_SERVICES);
        const serviceUrl =
          serviceApiUrl + "/" + product_source_id + "/accounts/" + account_id;
        return postFetch(serviceUrl, params, "DELETE");

      case "classes":
        const classeApiUrl = HttpHelper.getApiUrl(GET_CLASSES);
        const classeUrl = classeApiUrl + "/" + params.id;
        return postFetch(classeUrl, params, "DELETE");
      case "classe-accounts":
        let [classe_account_id, classe_id] = params.id.split("_");
        const classeAccountApiUrl = HttpHelper.getApiUrl(GET_CLASSES);
        const classeAccountUrl =
          classeAccountApiUrl +
          "/" +
          classe_id +
          "/accounts/" +
          classe_account_id;
        return postFetch(classeAccountUrl, params, "DELETE");
      case "usecases":
        const usecasesApiUrl = HttpHelper.getApiUrl(GET_USECASES);
        const usecasesUrl = usecasesApiUrl + "/" + params.id;
        return postFetch(usecasesUrl, params, "DELETE");
      case "usecase-reports":
        let usecase_report_id = params.id;
        const usecaseReportsApiUrl = HttpHelper.getApiUrl(GET_USECASE_REPORTS);
        const usecaseReportsUrl = usecaseReportsApiUrl + "/" +usecase_report_id;
        return postFetch(usecaseReportsUrl, params, "DELETE");
      case "usecase-access":
        console.log("delete usecase-access", params);
        let usecase_access_id = params.id;
        const usecaseAccessApiUrl = HttpHelper.getApiUrl(GET_USECASE_ACCESS);
        const usecaseAccessUrl = usecaseAccessApiUrl + "/" +usecase_access_id;
        return postFetch(usecaseAccessUrl, params, "DELETE");
      default:
        return Promise.reject(`Delete undefined for resource ${resource}`);
    }
  },

  deleteMany: (resource, params) => {
    return Promise.reject(`DeleteMany undefined for resource ${resource}`);
  },

  // Custom methods
  cloneReport : (report_id) => {
    const url = HttpHelper.getApiUrl(GET_REPORTS);
    return fetch(`${url}/${report_id}/clone`, { method: 'POST' })
            .then(response => response.json());
  }
};

export default dataProvider;
