class DateHelper {}

DateHelper.getFormattedDate = (dateString) => {
  if (!dateString) return null;
  const formatDate = (date) =>
    new Date(date).toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    });

  return formatDate(dateString);
};
DateHelper.getSecondsTohhmmss = (secs, fullInfo) => {
  secs = parseInt(secs);
  fullInfo = typeof fullInfo != "undefined" ? fullInfo : false;
  const pad = (num) => {
    return ("0" + num).slice(-2);
  };
  var minutes = Math.floor(secs / 60);
  secs = secs % 60;
  var hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  if (fullInfo || hours) {
    return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
  }
  if (minutes) {
    return `00:${pad(minutes)}:${pad(secs)}`;
  }
  if (secs) {
    return `00:00:${pad(secs)}`;
  }
  return "0";
};

DateHelper.getMinutesTohhmm = (minutes) => {
  minutes = parseInt(minutes);
  const pad = (num) => {
    return ("0" + num).slice(-2);
  };
  var hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  return `${pad(hours)}:${pad(minutes)}`;
};

DateHelper.getProperNb = (nb, k) => {
  return Math.round(nb * 100) / 100 + " " + k;
};

/**
 * This function generates a label for project date filters.
 *
 * List of dates filter forms :
 * {before_date: '2024-12-20', duration: -10, type: 'day'}
 * {after_date: '2024-12-26', duration: '2', type: 'day'}
 * {first_review: 2, type: 'day'}
 * {last_review: -2, type: 'day'}
 * {start: '2024-12-01', end: '2024-12-26'}
 *
 * @returns {string|null} - A formatted label for the project date filter, or null if no filter is provided.
 */
DateHelper.getProjectDateFilterLabel = (dateFilter) => {
  if (!dateFilter) return null;

  const formatDate = (date) =>
    new Date(date).toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    });

  const formatOffset = (value, type) => {
    const sign = value > 0 ? "+ " : "- ";
    const unit = Math.abs(value) > 1 ? `${type}s` : type;
    return `${sign}${Math.abs(value)} ${unit}`;
  };

  if (dateFilter.start && dateFilter.end) {
    return `${formatDate(dateFilter.start)} –> ${formatDate(dateFilter.end)}`;
  }

  if (dateFilter.first_review != null && dateFilter.type) {
    return `First review ${formatOffset(
      dateFilter.first_review,
      dateFilter.type
    )}`;
  }

  if (dateFilter.last_review != null && dateFilter.type) {
    return `Last review ${formatOffset(
      dateFilter.last_review,
      dateFilter.type
    )}`;
  }

  if (
    dateFilter.before_date &&
    dateFilter.duration != null &&
    dateFilter.type
  ) {
    return `${formatDate(dateFilter.before_date)} ${formatOffset(
      dateFilter.duration,
      dateFilter.type
    )}`;
  }

  if (dateFilter.after_date && dateFilter.duration != null && dateFilter.type) {
    return `${formatDate(dateFilter.after_date)} ${formatOffset(
      dateFilter.duration,
      dateFilter.type
    )}`;
  }

  return null;
};

DateHelper.getProjectDateFilterTooltipTitle = (
  dateFilter,
  first_review_date,
  last_review_date
) => {
  if (!dateFilter) return null;

  const formatDate = (date) =>
    new Date(date).toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    });

  const calculateDelta = (nb, type) => {
    if (type == "month") {
      return nb * 30;
    } else if (type == "year") {
      return nb * 365;
    }
  };

  const calculateNewDate = (fromDate, nb, type) => {
    var date = new Date(fromDate);
    date.setDate(date.getDate() + calculateDelta(nb, type));

    return date;
  };

  let start_date = first_review_date;
  let end_date = last_review_date;

  if (dateFilter.first_review && first_review_date == null) {
    return "First review date not available";
  }

  if (dateFilter.last_review && last_review_date == null) {
    return "last review date not available";
  }

  if (dateFilter.start && dateFilter.end) {
    start_date = dateFilter.start;
    end_date = dateFilter.end;
  }

  if (dateFilter.first_review != null && dateFilter.type) {
    end_date = calculateNewDate(
      start_date,
      dateFilter.first_review,
      dateFilter.type
    );
  }

  if (dateFilter.last_review != null && dateFilter.type) {
    start_date = calculateNewDate(
      end_date,
      dateFilter.last_review,
      dateFilter.type
    );
  }

  if (
    dateFilter.before_date &&
    dateFilter.duration != null &&
    dateFilter.type
  ) {
    start_date = calculateNewDate(
      end_date,
      dateFilter.duration,
      dateFilter.type
    );
  }

  if (dateFilter.after_date && dateFilter.duration != null && dateFilter.type) {
    start_date = dateFilter.after_date;

    end_date = calculateNewDate(
      start_date,
      dateFilter.duration,
      dateFilter.type
    );
  }

  return `Covers ${formatDate(start_date)} to ${formatDate(end_date)}`;
};

export default DateHelper;
