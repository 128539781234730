import React from "react";
import { withTranslation } from "react-i18next";
import Loader from "../../../../components/loader/Loader";
import Modal from "../../../../components/modal/Modal";
import { TextField } from "@mui/material";

class EditTeamModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        id: props.item.id,
        title: props.item.title,
      },
      initialFormData: {
        id: props.item.id,
        title: props.item.title,
      },
    };
    this.modalRef = React.createRef();
    this.onInputChange = this.onInputChange.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.buttonIsEnabled = this.buttonIsEnabled.bind(this);
  }

  onCancelClick() {
    if (this.modalRef && this.modalRef.current) {
      this.modalRef.current.onModalCloseClick();
    }
  }

  onInputChange(e) {
    let formData = Object.assign({}, this.state.formData);
    formData[e.target.name] = e.target.value;
    this.setState({
      formData: formData,
    });
  }

  buttonIsEnabled() {
    return (
      JSON.stringify(this.state.formData) !==
      JSON.stringify(this.state.initialFormData)
    );
  }

  render() {
    const { t } = this.props;
    const buttonIsEnabled = this.buttonIsEnabled();
    return (
      <Modal
        ref={this.modalRef}
        display={this.props.display}
        onClose={this.props.onClose}
      >
        <div className="font-size-30 font-inter-700 color-000 padding-bottom-40">
          {t("Edit team")}
        </div>
        <form>
          <div className="padding-bottom-36">
            <label className="display-block color-969696 font-size-15 font-inter-600 padding-bottom-4">
              {t("Team name")}
            </label>
            <TextField
              type="text"
              name="title"
              placeholder={t("Name")}
              defaultValue={this.state.formData.title}
              disabled={this.props.loading}
              onChange={this.onInputChange}
              sx={{ width: "100%" }}
            />
          </div>
          <div className="overflow-hidden">
            {!this.props.loading && (
              <div className="float-right">
                <button
                  className="button backgroundless"
                  type="button"
                  onClick={this.onCancelClick}
                >
                  {t("Cancel")}
                </button>
                <button
                  className="button backgroundless margin-left-5"
                  disabled={!buttonIsEnabled}
                  onClick={() => {
                    this.props.onEditItem(this.state.formData);
                  }}
                >
                  {t("Save")}
                </button>
              </div>
            )}
            {this.props.loading && (
              <div className="float-right" style={{ padding: "2px 0" }}>
                <Loader />
              </div>
            )}
          </div>
        </form>
      </Modal>
    );
  }
}

export default withTranslation()(EditTeamModal);
