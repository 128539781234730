import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Show,
  ShowButton,
  TextInput,
  ReferenceInput,
  SelectInput,
  DateField,
  UrlField,
  SearchInput,
  Loading,
  useListContext,
  SimpleShowLayout,
  RichTextField,
} from "react-admin";
import IconUrlField from "../../components/form/field/IconUrlField";
import MediaTypeField from "./elements/MediaTypeField";
import FilledDateField from "./elements/FilledDateField";

const postFilters = [
    <TextInput label="ReportID" source="analysis_report_id" className="remove-line-height"  />,
    <TextInput label="SectionID" source="section_id" className="remove-line-height"  />,
  <TextInput label="ProjectID" source="project_id" className="remove-line-height"  />,
  <TextInput label="DatasourceID" source="datasource_id" className="remove-line-height"  />,

];

export const LogPromptList = (props) => (
  <List filters={postFilters} {...props} perPage={25} sort={{ field: 'id', order: 'DESC' }}>
    <MyDatagrid />
  </List>
);

const MyDatagrid = () => {
  const { isLoading, isFetching } = useListContext();

  if (isLoading || isFetching) {
    return <Loading />;
  }

  return (
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField label="ID" source="id" />
      <TextField label="ReportID" source="analysis_report_id" />
      <TextField label="Hash" source="hash" />
      <TextField label="SectionID" source="section_id" />
      <TextField label="Section Order" source="section_display_order" />
      <TextField label="ProjectID" source="project_id" />
      <TextField label="DatasourceID" source="datasource_id" />
      <FilledDateField label="Created at" source="created_at" hidden={false} />
    </Datagrid>
  );
};

export const LogPromptShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="ReportID" source="analysis_report_id" />
            <TextField label="Hash" source="hash" />
            <TextField label="SectionID" source="section_id" />
            <TextField label="Section Order" source="section_display_order" />
            <TextField label="ProjectID" source="project_id" />
            <TextField label="DatasourceID" source="datasource_id" />
            <RichTextField source="prompt" />
            <RichTextField source="response" />
            <DateField label="Created at" source="created_at" />
        </SimpleShowLayout>
    </Show>
);