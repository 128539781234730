import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const STooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#616161", // Couleur de fond
    color: "#fff", // Couleur du texte
    fontFamily: '"Inter", sans-serif',
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0.14px",
    borderRadius: "4px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#616161", // Couleur de la flèche
  },
}));

export default STooltip;
