// Graph1.js
import React, {
  Fragment,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import StringHelper from "../../../app/helpers/StringHelper";
import EmotionHelper from "../../../app/helpers/EmotionHelper";
import ProjectRoundedEmotion from "../../projectWorkspace/elements/ProjectRoundedEmotion";
import moment from "moment-timezone";
import image from "../../../res/images/icons/last-review.svg";
import interrogationGreyIcon from "../../../res/images/icons/interrogationgrey.svg";
import { useProjectDataManager } from "../../../dataManagers/projectDataManager";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import STypography from "../../../components/SMui/STypography";
import STooltip from "../../../components/SMui/STooltip";

const StatisticsView = forwardRef(
  (
    {
      projectId,
      datasourceId,
      datasources = null,
      keywords = null,
      languages = null,
      verified = null,
      attributes = null,
      stores = null,
      dates = null,
      scores = null,
      countFailed = false,
      tab = null,
    },
    ref
  ) => {
    const {
      fetchStatistics,
      clearStatistics,
      isLoading,
      projectStatistics,
      errors,
    } = useProjectDataManager();

    const fetchDataManagerStatistics = async () => {
      const formData = {
        datasource_id: datasourceId,
        datasources: datasources,
        keywords: keywords,
        languages: languages,
        stores: stores,
        dates: dates,
        scores: scores,
        verified: verified,
        attributes: attributes,
        ignoreProjectFilters: tab == "inspect",
      };

      try {
        await fetchStatistics(projectId, formData);
      } catch (error) {
        console.error(error);
      }
    };

    useImperativeHandle(ref, () => ({
      refreshStatistics() {
        fetchDataManagerStatistics();
      },
    }));

    useEffect(() => {
      fetchDataManagerStatistics();
    }, [
      projectId,
      datasourceId,
      JSON.stringify(datasources),
      JSON.stringify(keywords),
      JSON.stringify(languages),
      JSON.stringify(verified),
      JSON.stringify(attributes),
      JSON.stringify(stores),
      JSON.stringify(dates),
      JSON.stringify(scores),
    ]);

    if (projectStatistics && !countFailed) {
      projectStatistics.datasources = projectStatistics.datasources.filter(
        (elm) => elm.status !== "FAILED"
      );
    }

    const sources = projectStatistics?.datasources?.length;
    // total_obs : 113
    // total_obs_used: 80
    // total_reviews: 113
    let relevant_reviews = projectStatistics?.data.total_obs_used;
    let reviews = projectStatistics?.data.total_obs;
    let total_reviews = projectStatistics?.data.total_reviews;
    let totalReviewsPercent =
      total_reviews && reviews
        ? Number(reviews / total_reviews).toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 0,
          })
        : "0%";
    let relevantReviewsPercent =
      relevant_reviews && reviews
        ? Number(relevant_reviews / reviews).toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 0,
          })
        : "0%";

    let positive =
      projectStatistics?.data.percent_pos !== null &&
      projectStatistics?.data.percent_pos !== undefined
        ? Number(projectStatistics?.data.percent_pos).toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 0,
          })
        : null;

    let negative =
      projectStatistics?.data.percent_neg !== null &&
      projectStatistics?.data.percent_neg !== undefined
        ? Number(projectStatistics?.data.percent_neg).toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 0,
          })
        : null;

    let score = projectStatistics?.data.average_score;
    let feeling = projectStatistics?.data.all_emotions;
    const sourceTooltip = projectStatistics?.datasources;
    const updated_at = projectStatistics?.last_review_date;

    if (isLoading) {
      return (
        <div
          className="central-content skeleton-box"
          style={{ width: "100%", height: "56px" }}
        />
      );
    }

    if (errors) {
      return (
        <Box
          sx={{
            height: "60px",
            width: "100%",
            border: "1px solid #e04f77",
            borderRadius: "5px",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "larger",
              fontWeight: "bold",
              color: "#e04f77",
            }}
          >
            An error occurred...
          </Typography>
        </Box>
      );
    }

    const showEmotion = () => {
      switch (typeof feeling) {
        case "string":
          return (
            <div>
              <div
                id="color-dot"
                style={{
                  backgroundColor: EmotionHelper.getEmotionColor(feeling),
                  width: "8px",
                  height: "8px",
                  borderRadius: "100%",
                }}
              />
              <h3 id="feeling">
                {feeling ? (
                  StringHelper.capitalizeFirstLetter(feeling)
                ) : (
                  <span style={{ fontSize: "24px" }}>{"-"}</span>
                )}
              </h3>
            </div>
          );

        case "object":
          // check if null (because typeof null return object)
          if (feeling === null) {
            return (
              <h3 style={{ fontSize: "24px" }} id="feeling">
                {"-"}
              </h3>
            );
          }
          return (
            <ProjectRoundedEmotion
              allEmotions={feeling}
              withTooltip={true}
              projectId={projectId}
              datasourceId={datasourceId}
              inScore={true}
            />
          );

        case "undefined":
          return (
            <h3 style={{ fontSize: "24px" }} id="feeling">
              {"-"}
            </h3>
          );

        default:
          return (
            <h3 style={{ fontSize: "24px" }} id="feeling">
              {"-"}
            </h3>
          );
      }
    };

    if (
      typeof score !== "number" ||
      score == "-" ||
      score == null ||
      score == undefined
    ) {
      score = "-";
    } else {
      score = Math.round(score * 100) / 100;
    }

    return (
      <div id="scores">
        {/* sources */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background: "white",
            position: "absolute",
            top: "-7px",
            padding: "0 4px",
          }}
        >
          <STypography
            sx={{
              color: "#757575",
              fontSize: "10px",
            }}
          >
            KEY INSIGHTS METRICS
          </STypography>
          <STooltip
            arrow
            title={
              tab === "inspect"
                ? "Based on all project reviews"
                : "Based on reviews matching your project settings"
            }
          >
            <img
              style={{ marginLeft: "2px" }}
              width="14px"
              src={interrogationGreyIcon}
              alt=""
            />
          </STooltip>
        </Box>

        <div id="sources" className="number-box">
          <p>{`Source${sources > 1 ? "s" : ""}`}</p>
          <h3>{sources || "-"}</h3>

          <div id="source-tooltip">
            <div id="cachetop"></div>
            {sourceTooltip !== undefined && (
              <div className="content">
                {sourceTooltip.map((elm, i) => {
                  const lang = [];
                  elm.languages?.forEach((langItem) => {
                    lang.push(langItem.label);
                  });
                  return (
                    <div className="box" key={i}>
                      <div className="item">
                        <img
                          src={
                            process.env.REACT_APP_API_URL +
                            elm.datasource_type[0]?.icon
                          }
                          alt="datasource's logo"
                          height="22px"
                        />
                        <div className="item-text">
                          <p className="title">{elm.title}</p>
                          <p>{lang.join(", ")}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div id="cachebottom"></div>
          </div>
        </div>

        <div className="separator"></div>

        <Box sx={{ display: "flex", gap: "26px", height: "100%" }}>
          {/* reviews */}
          <div id="total-reviews" className="number-box">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "2px",
              }}
            >
              <p>Reviews</p>
              {/* <img
                style={{ marginLeft: "0px" }}
                src={interrogationGreyIcon}
                alt="tooltip review"
                height="14px"
              /> */}
            </Box>
            <h3>{reviews}</h3>
            <div id="reviews-tooltip">
              <div id="cachetop"></div>
              <div className="content">
                <div className="top-infos">
                  <div className="review-title">
                    <h3>{reviews || "0"}</h3>
                    <p>out of {total_reviews}</p>
                  </div>
                </div>
                <div className="progress-bar">
                  <div
                    className="progress-bar-content"
                    style={{ width: `${totalReviewsPercent}` }}
                  ></div>
                </div>
                <p>Included/Total</p>
              </div>
              <div id="cachebottom"></div>
            </div>
          </div>

          {/* relevant reviews */}

          <div id="reviews" className="number-box">
            <p>Relevant reviews</p>
            <h3>
              {relevantReviewsPercent || "-"}{" "}
              {relevantReviewsPercent === "-" ? (
                ""
              ) : (
                <div className="progress-bar">
                  <div
                    className="progress-bar-content"
                    style={{ width: `${relevantReviewsPercent}` }}
                  ></div>
                </div>
              )}
            </h3>

            <div id="relevant-tooltip">
              <div id="cachetop"></div>
              <div className="content">
                <div className="top-infos">
                  <div className="relevant-title">
                    <h3>{relevant_reviews || "0"}</h3>
                    <p>out of {reviews}</p>
                  </div>
                  <h3>{relevantReviewsPercent || "-"}</h3>
                </div>
                <div className="progress-bar">
                  <div
                    className="progress-bar-content"
                    style={{ width: `${relevantReviewsPercent}` }}
                  ></div>
                </div>
                <p>relevants reviews</p>
                <div className="last-review">
                  {updated_at !== null && (
                    <Fragment>
                      <img src={image} alt="" />
                      <p>
                        Last review:{" "}
                        {moment.utc(updated_at).local().fromNow(true)}
                      </p>
                    </Fragment>
                  )}
                </div>
              </div>
              <div id="cachebottom"></div>
            </div>
          </div>
        </Box>
        <div className="separator"></div>

        {/* positive */}
        <div className="number-box">
          <p>Positive</p>
          <h3 className="positive">{positive || "-"}</h3>
        </div>

        {/* negative */}
        <div className="number-box">
          <p>Negative</p>
          <h3 className="negative">{negative || "-"}</h3>
        </div>

        <div className="separator"></div>

        {/* score */}
        <div className="number-box">
          <p>Score</p>
          <h3>{score}</h3>
        </div>

        <div className="separator"></div>

        {/* top feeling */}
        <div className="feeling-box feeling">
          <p>Top feeling</p>
          {showEmotion()}
        </div>
      </div>
    );
  }
);

export default StatisticsView;
