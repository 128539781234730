import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import {
  ACCOUNT_PLAN,
  PLAN_JSON_LOGIC_KEYS,
  globalConstants,
} from "../../app/constants";
import Header from "../../components/header/Header";

import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { projectService } from "../../app/services/project.service";
import HttpHelper from "../../app/helpers/HttpHelper";
import UserHelper from "../../app/helpers/UserHelper";

//import "./tabViews/overview/OverView.css";

import IntercomHelper from "../../app/helpers/IntercomHelper";
import {
  Box,
  Button,
  Stack,
  Tab,
  Tabs,
  Typography,
  styled,
} from "@mui/material";
import ProjectDetailView from "../projectDetail/ProjectDetailView";
import ProjectNewInsight from "../projectNewInsight/projectNewInsight";
import ProjectReportView from "../projectReport/ProjectReportView";
import ProjectWorkspaceView from "../projectWorkspace/ProjectWorkspaceView";
import {
  EDIT_PROJECT,
  PROJECT_ADD_MEDIA,
  PROJECT_DETAIL,
  PROJECT_EDIT,
  PROJECT_SEARCH_MEDIA,
  ROUTES,
} from "../../app/routing";

import DeleteModal from "../../components/modal/DeleteModal";
import Loader from "../../components/loader/Loader";
import { SessionStorageService } from "../../app/services/storage.service";
import { planJsonLogicService } from "../../app/services/plan.jsonLogic.service";

import InsightsIcon from "@mui/icons-material/Insights";
import Close from "../../res/images/icons/pic-tag-clear.svg";
import upgradeIcon from "../../res/images/icons/upgrade_icon.svg";
import illus_quotas from "../../res/images/illustrations/illus-quotas-popup.svg";
import Share from "../../res/images/icons/share.svg";
import Print from "../../res/images/icons/print.svg";
import OpeninNewFilled from "../../res/images/icons/OpeninNewFilled.svg";
import ContentCopyFilled from "../../res/images/icons/ContentCopyFilled.svg";
import Globe from "../../res/images/icons/globe.svg";
import Export from "../../res/images/icons/export-doc.svg";
import ExportExcel from "../../res/images/icons/export-excel.svg";
import ExportPPT from "../../res/images/icons/export-ppt.png";
import ExportPDF from "../../res/images/icons/export-pdf.png";
import star_icon_grey from "../../res/images/icons/star-icon-grey.svg";

import STypography from "../../components/SMui/STypography";
import ReviewsRoundedIcon from "@mui/icons-material/ReviewsRounded";
import Link from "../../components/Link";
import RoundSelect from "../../components/roundSelect/RoundSelect";

import { AccountDataManagerContext } from "../../dataManagers/accountDataManager";
import { mediaService } from "../../app/services/media.service";
import { workspaceService } from "../../app/services/workspace.service";
import LanguageIcon from "@mui/icons-material/Language";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import SChip from "../../components/SMui/SChip";
import DateHelper from "../../app/helpers/DateHelper";
import { ProjectDataManager } from "../../dataManagers/projectDataManager";
import StringHelper from "../../app/helpers/StringHelper";
import STooltip from "../../components/SMui/STooltip";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "#000",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "#9e9e9e",
    "&.Mui-selected": {
      color: "#000",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

function getDefaultTab(search) {
  const urlParams = HttpHelper.getUrlParameters(search);
  if (urlParams && urlParams.hasOwnProperty("tab")) {
    return urlParams.tab;
  }

  return "overview";
}

function getDefaultDatasource(search) {
  const urlParams = HttpHelper.getUrlParameters(search);
  if (urlParams && urlParams.hasOwnProperty("datasourceId")) {
    return parseInt(urlParams.datasourceId);
  }

  return null;
}

class ProjectView extends React.Component {
  static contextType = AccountDataManagerContext;

  constructor(props) {
    super(props);
    const projectId = props.match.params.id
      ? parseInt(props.match.params.id)
      : null;

    this.state = {
      projectId: projectId,
      datasourceId: getDefaultDatasource(props.location.search),
      project: null,
      datasourceList: [],
      tabValue: getDefaultTab(props.location.search), // first tab value
      workspaceFormData: {},
      workspaceDefaultClassId: null,
      displayOriginalReviews: false,
      deleteProject: {
        display: false,
        loading: false,
        error: null,
      },
      sharePopup: false,
      print: false,
      freePlanPopup: null,
      ////////
      enterpriseFeatureIsDisabled: true,
      currentPlan: null,
      planPopup: false,
      quotasExceeded: false,
      quotasPopup: false,
      quotasPopupData: {
        totalReviews: null,
        maxReviews: null,
        isUpdate: false,
        isEditProject: false,
      },
    };

    this.displayToastErrorMessage = this.displayToastErrorMessage.bind(this);
    this.renderTabChildren = this.renderTabChildren.bind(this);
    this.fetchProject = this.fetchProject.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.pageTitleHandler = this.pageTitleHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.onArchiveProject = this.onArchiveProject.bind(this);

    this.onOpenDeleteProjectModal = this.onOpenDeleteProjectModal.bind(this);
    this.onCloseDeleteProjectModal = this.onCloseDeleteProjectModal.bind(this);
    this.onDeleteProject = this.onDeleteProject.bind(this);

    this.checkAddDatasourceRules = this.checkAddDatasourceRules.bind(this);

    this.handleClickAddDatasource = this.handleClickAddDatasource.bind(this);
    this.handleDeleteDatasource = this.handleDeleteDatasource.bind(this);
    this.handleClickSearchDatasource =
      this.handleClickSearchDatasource.bind(this);

    this.getPlanPopup = this.getPlanPopup.bind(this);
    this.closePlanPopup = this.closePlanPopup.bind(this);

    this.openQuotasPopup = this.openQuotasPopup.bind(this);
    this.getQuotasPopUp = this.getQuotasPopUp.bind(this);
    this.closeQuotasPopUp = this.closeQuotasPopUp.bind(this);

    this.copyShareLink = this.copyShareLink.bind(this);
    this.getShareTooltipContent = this.getShareTooltipContent.bind(this);
    this.closeSharePopup = this.closeSharePopup.bind(this);
    this.openSharePopup = this.openSharePopup.bind(this);
    this.handlePrint = this.handlePrint.bind(this);
    this.print = this.print.bind(this);
    this.getFreePlanPopup = this.getFreePlanPopup.bind(this);
    this.closeFreePlanPopup = this.closeFreePlanPopup.bind(this);

    this.getFilename = this.getFilename.bind(this);
    this.getPPTPDFFilename = this.getPPTPDFFilename.bind(this);
    this.generateReport = this.generateReport.bind(this);
    this.handleExportVariables = this.handleExportVariables.bind(this);

    this.handleExport = this.handleExport.bind(this);
    this.handleExportPDF = this.handleExportPDF.bind(this);
    this.handleExportPPT = this.handleExportPPT.bind(this);
    this.generatePDFPPT = this.generatePDFPPT.bind(this);

    this.abortController = new AbortController();
    this.abortControllerSignal = this.abortController.signal;

    SessionStorageService.set("update_media_referrer", props.location.pathname);

    IntercomHelper.updateIntercom();
  }

  componentDidMount() {
    this._ismounted = true;
    this.checkAddDatasourceRules();
    this.fetchProject();
  }

  componentWillUnmount() {
    this._ismounted = false;
    this.abortController.abort();
  }

  async checkAddDatasourceRules(numberOfReviews = 0, callback = null) {
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.MEDIAS_ADD_MEDIA,
      {
        project_id: this.state.projectId,
        other_datas: { new_datasource_nb_reviews: numberOfReviews },
      },
      (response) => {
        const max = response.data.max_reviews_for_account;
        const total = response.current_data.current_reviews_for_account;

        this.setState(
          {
            quotasExceeded: response.status === "error",
            currentPlan: response.data.plan,
            enterpriseFeatureIsDisabled: ![
              ACCOUNT_PLAN.ENTERPRISE,
              ACCOUNT_PLAN.ENTERPRISE_SAAS,
              ACCOUNT_PLAN.MANUAL_BILLING,
            ].includes(response.data.plan),
            quotasPopupData: {
              totalReviews: total,
              maxReviews: max,
              isUpdate: false,
              isEditProject: false,
            },
          },
          () => {
            // Appel du callback avec la valeur de response.status après la mise à jour du state
            if (callback) {
              callback(response.status);
            }
          }
        );
      },
      (error) => {
        console.error(error);
        if (callback) {
          callback("error");
        }
      }
    );
  }

  async fetchProject() {
    await projectService.get.get(
      this.state.projectId,
      (response) => {
        const accounts = UserHelper.getAllUserAccounts().filter(
          (account) => account.id === response.accountId
        );
        if (accounts && accounts.length)
          UserHelper.updateCurrentAccountById(response.accountId);

        this.setState({
          project: response,
        });
        this.fetchData();
      },
      (error) => {
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      null,
      this.abortControllerSignal
    );
  }

  fetchData() {
    mediaService.get.getProjectMedias(
      this.state.projectId,
      null,
      (response) => {
        const successList = response.data.filter(
          (elm) =>
            elm.status === "SUCCESS" ||
            (elm.status === "INPROGRESS" &&
              elm.total_obs?.total_obs &&
              elm.main_emotion &&
              elm.percent_neg &&
              elm.percent_pos &&
              elm.reports &&
              elm.reports?.length)
        );

        this.setState({
          datasourceList: successList,
        });
      },
      (error) => {
        this.displayToastErrorMessage(error, null, null);
      },
      this.abortControllerSignal
    );
  }

  onArchiveProject(onThen) {
    projectService.put.archive(
      this.state.projectId,
      (success) => {
        this.context.fetchAccountReviewsData();
        setTimeout(() => {
          this.props.addToast(
            'Project "' + this.state.project.name + '" successfully archived',
            { type: "success", autoDismiss: true }
          );
        }, 800);
        if (typeof onThen === "function") {
          onThen();
        }
        this.props.history.push(ROUTES.HOME);
      },
      (error) => {
        this.displayToastErrorMessage(error, onThen);
      }
    );
  }

  onOpenDeleteProjectModal() {
    this.setState({
      deleteProject: {
        display: true,
        item: this.state.project,
        loading: false,
        error: null,
      },
    });
  }

  onCloseDeleteProjectModal() {
    this.setState({
      deleteProject: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
    });
  }

  onDeleteProject() {
    let deleteData = Object.assign({}, this.state.deleteProject);
    deleteData.loading = true;
    this.setState({
      deleteProject: deleteData,
    });

    projectService.delete.delete(
      this.state.projectId,
      (response) => {
        this.context.fetchAccountReviewsData();
        setTimeout(() => {
          this.props.addToast(
            'Project "' +
              this.state.deleteProject.item.name +
              '" successfully deleted',
            { type: "success", autoDismiss: true }
          );
        }, 800);
        this.props.history.push(ROUTES.HOME);
      },
      (error) => {
        deleteData.loading = false;
        this.displayToastErrorMessage(error, null, {
          deleteProject: deleteData,
        });
      }
    );
  }

  handlePrint() {
    if (this.state.currentPlan === ACCOUNT_PLAN.FREE) {
      this.setState({
        freePlanPopup: true,
      });
      return;
    } else {
      this.setState({
        print: true,
      });
    }
  }

  print() {
    setTimeout(() => {
      window.print();
    }, 200);
    window.addEventListener("afterprint", (event) => {
      this.setState({
        print: false,
      });
    });
  }

  copyShareLink(e) {
    e.preventDefault();
    navigator.clipboard.writeText(this.state.project.public_report_url_link);
    this.setState({
      clipboardToast: true,
    });
    setTimeout(() => {
      this.setState({
        clipboardToast: false,
      });
    }, 1500);
  }

  openSharePopup() {
    if (this.state.currentPlan === ACCOUNT_PLAN.FREE) {
      this.setState({
        freePlanPopup: true,
      });
      return;
    } else {
      this.setState({
        sharePopup: true,
      });
    }
  }

  getShareTooltipContent() {
    const { t } = this.props;
    return (
      <div className="share-tooltip">
        <img
          onClick={this.closeSharePopup}
          className="close"
          src={Close}
          alt=""
        />

        <h3>Share "{this.state.project.name}"</h3>

        <p>
          <img src={Globe} alt="" />
          Anyone with this link can view this report
        </p>

        <div className="btn-box">
          <div className="content">
            <span className="actions" onClick={this.copyShareLink}>
              <img src={ContentCopyFilled} alt="" />
              {t("Copy link")}
            </span>

            <span className="preview">
              <img src={OpeninNewFilled} alt="" />
              <Link
                to={this.state.project.public_report_url_link}
                type="a"
                target="_blank"
              >
                <span className="actions">Preview</span>
              </Link>
            </span>

            {this.state.clipboardToast && (
              <span className="clipboard-toast">Copied on clipboard!</span>
            )}
          </div>

          <span onClick={this.closeSharePopup} className="button">
            Done
          </span>
        </div>
      </div>
    );
  }

  closeSharePopup() {
    this.setState({
      sharePopup: false,
    });
  }

  handleExportVariables() {
    if (this.state.currentPlan === ACCOUNT_PLAN.FREE) {
      this.setState({
        freePlanPopup: true,
      });
      return;
    }
    this.generateReport();
  }

  handleExportInspectData() {
    const projectId = this.state.projectId;
    const filename = `Report ${this.state.project.name}.xlsx`;
    workspaceService.get.exportReport(
      projectId,
      filename,
      this.state.workspaceFormData,
      (_resp) => {},
      (error) => {
        if (!this._ismounted) {
          return;
        }

        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}${month}${day}`;
  }
  getFilename() {
    const { datasourceId, datasourceList, project } = this.state;

    let selectedDatasourceName = null;

    if (datasourceId) {
      const datasource = datasourceList?.find((e) => e.id == datasourceId);
      if (datasource) {
        selectedDatasourceName = datasource.title;
      }
    }

    let baseName = datasourceId ? selectedDatasourceName : project.name;
    baseName = baseName?.replace(/ /g, "_"); // Remplace les espaces par des underscores
    if (!baseName) {
      baseName = "report";
    }
    const currentDate = this.getCurrentDate();
    return `${baseName}_${currentDate}`;
  }

  getPPTPDFFilename(exportType) {
    const filename = this.getFilename();
    return `${filename}.${exportType}`;
  }

  generateReport() {
    const filename = this.getFilename();
    projectService.get.getExcelReport(
      this.state.projectId,
      `${filename}.xlsx`,
      { datasource_id: this.state.datasourceId },
      null,
      (error) => {
        this.displayToastErrorMessage(error);
      }
    );
  }

  handleExport() {
    if (this.state.currentPlan === ACCOUNT_PLAN.FREE) {
      this.setState({
        freePlanPopup: true,
      });
      return;
    }
    const { datasourceId, datasourceList } = this.state;

    let selectedDatasourceName = null;

    if (datasourceId) {
      const datasource = datasourceList?.find((e) => e.id == datasourceId);
      if (datasource) {
        selectedDatasourceName = datasource.title;
      }
    }

    const filename = this.getFilename();
    projectService.get.getDocxReport(
      this.state.projectId,
      `${filename}.docx`,
      { datasource_id: this.state.datasourceId },
      null,
      (error) => {
        this.displayToastErrorMessage(error);
      }
    );
  }

  handleExportPDF() {
    if (this.state.currentPlan === ACCOUNT_PLAN.FREE) {
      this.setState({
        freePlanPopup: true,
      });
      return;
    }
    this.generatePDFPPT("pdf");
  }

  handleExportPPT() {
    if (this.state.currentPlan === ACCOUNT_PLAN.FREE) {
      this.setState({
        freePlanPopup: true,
      });
      return;
    }

    this.generatePDFPPT("pptx");
  }

  generatePDFPPT(exportType) {
    const { projectId, datasourceId } = this.state;

    this.setState({ exportLoader: true });
    const filename = this.getPPTPDFFilename(exportType);

    projectService.get.getPPDFPPTReport(
      projectId,
      datasourceId,
      exportType,
      filename,
      () => {
        this.setState({ exportLoader: false });
      },
      (error) => {
        this.setState({ exportLoader: false });
        this.displayToastErrorMessage(error);
      }
    );
  }

  closeFreePlanPopup() {
    this.setState({
      freePlanPopup: false,
    });
  }

  getFreePlanPopup() {
    const { t } = this.props;
    return (
      <div className="position-absolute share-tooltip-free">
        <img
          onClick={this.closeFreePlanPopup}
          className="close"
          src={Close}
          alt=""
        />

        <h3>Upgrade your Insights</h3>

        <p style={{ letterSpacing: "0.15px" }}>
          You are currently on a Free plan. To access all the details in the
          current Insights report, please upgrade to either the Pro or Business
          plan.
        </p>

        <Link to={ROUTES.PLAN_CHOOSE} type="a" target="_blank">
          <span className="actions color-3a79e6">
            See what you get when you upgrade
          </span>
        </Link>

        <div className="btn-box" style={{ marginTop: "40px" }}>
          <div className="content" style={{ justifyContent: "flex-end" }}>
            <span
              className="actions color-3a79e6"
              onClick={this.closeFreePlanPopup}
            >
              {t("Maybe later")}
            </span>
            <span onClick={this.closeFreePlanPopup} className="button">
              <Link
                to={ROUTES.PLAN_CHOOSE}
                style={{ textDecoration: "none", color: "white" }}
              >
                Upgrade
              </Link>
            </span>
          </div>
        </div>
      </div>
    );
  }

  pageTitleHandler() {
    return `${this.state.project ? this.state.project.name + " -" : ""} ${
      globalConstants.WEBSITE_TITLE
    }`;
  }

  handleChange(event, newValue) {
    window.scrollTo(0, 0);
    let datasourceId = this.state.datasourceId;
    if (newValue == "overview") {
      datasourceId = null;
    }
    this.setState({ tabValue: newValue, datasourceId: datasourceId }, () => {
      this.updateUrl();
    });
  }

  updateUrl() {
    let search = "?tab=";
    search = search + this.state.tabValue;
    if (this.state.datasourceId) {
      search = search + "&datasourceId=" + this.state.datasourceId;
    }

    this.props.history.replace({
      pathname: PROJECT_DETAIL(this.state.projectId),
      search: search,
    });
  }

  displayToastErrorMessage(error, onThen, updatedState) {
    if (!this._ismounted) {
      return;
    }
    this.props.addToast(HttpHelper.getErrorMessage(error), {
      type: "error",
      autoDismiss: true,
    });
    if (typeof onThen == "function") {
      onThen();
    }
    if (updatedState) {
      this.setState(updatedState);
    }
  }

  handleClickAddDatasource() {
    if (this.state.quotasExceeded) {
      this.setState({ quotasPopup: true });
    } else {
      this.props.history.push(PROJECT_ADD_MEDIA(this.state.projectId));
    }
  }

  handleDeleteDatasource() {
    this.checkAddDatasourceRules();
    this.fetchProject();
  }

  handleClickSearchDatasource() {
    if (this.state.quotasExceeded) {
      this.setState({ quotasPopup: true });
    } else {
      this.props.history.push(PROJECT_SEARCH_MEDIA(this.state.projectId));
    }
  }

  renderTabChildren(tabValue) {
    if (tabValue === "overview") {
      return (
        <ProjectDetailView
          key="overview"
          history={this.props.history}
          addToast={this.props.addToast}
          projectId={this.state.projectId}
          selectDatasourceId={(datasourceId, tab = "insights") =>
            this.setState(
              {
                datasourceId: datasourceId,
                tabValue: tab,
              },
              () => {
                this.updateUrl();
              }
            )
          }
          handleClickAddDatasource={this.handleClickAddDatasource}
          handleDeleteDatasource={this.handleDeleteDatasource}
          handleClickSearchDatasource={this.handleClickSearchDatasource}
          onOpenQuotasPopup={this.openQuotasPopup}
        />
      );
    } else if (tabValue === "insights") {
      return (
        <ProjectNewInsight
          key="insights"
          history={this.props.history}
          addToast={this.props.addToast}
          projectId={this.state.projectId}
          datasourceId={this.state.datasourceId}
          planKey={this.state.currentPlan}
          print={this.state.print}
          handleClickAddDatasource={this.handleClickAddDatasource}
          handleClickSearchDatasource={this.handleClickSearchDatasource}
          selectDatasourceId={(datasourceId) =>
            this.setState({ datasourceId: datasourceId }, () => {
              this.updateUrl();
            })
          }
        />
      );
    } else if (tabValue === "in-depth") {
      return (
        <ProjectReportView
          key="in-depth"
          history={this.props.history}
          addToast={this.props.addToast}
          projectId={this.state.projectId}
          datasourceId={this.state.datasourceId}
          print={this.state.print}
          planKey={this.state.currentPlan}
          handleClickAddDatasource={this.handleClickAddDatasource}
          handleClickSearchDatasource={this.handleClickSearchDatasource}
          selectDatasourceId={(datasourceId) =>
            this.setState({ datasourceId: datasourceId }, () => {
              this.updateUrl();
            })
          }
          openWorkspaceWithKeywords={(keywords) => {
            this.setState(
              {
                workspaceFormData: {
                  ...this.state.workspaceFormData,
                  keywords,
                },
                tabValue: "inspect",
              },
              () => {
                this.updateUrl();
              }
            );
          }}
        />
      );
    } else if (tabValue === "inspect") {
      return (
        <ProjectWorkspaceView
          key="inspect"
          history={this.props.history}
          addToast={this.props.addToast}
          projectId={this.state.projectId}
          datasourceId={this.state.datasourceId}
          planKey={this.state.currentPlan}
          print={this.state.print}
          defaultKeywords={this.state.workspaceFormData.keywords}
          defaultClassId={this.state.workspaceDefaultClassId}
          displayOriginalReviews={this.state.displayOriginalReviews}
          handleClickAddDatasource={this.handleClickAddDatasource}
          handleClickSearchDatasource={this.handleClickSearchDatasource}
          updateDisplayOriginalReviews={(val) =>
            this.setState({ displayOriginalReviews: val })
          }
          updateFormData={(formData) => {
            this.state.workspaceFormData = formData;
          }}
          selectDatasourceId={(datasourceId) => {
            this.setState(
              {
                datasourceId: datasourceId,
              },
              () => {
                this.updateUrl();
              }
            );
          }}
        />
      );
    }

    return <Loader />;
  }

  closePlanPopup() {
    this.setState({
      planPopup: false,
    });
  }

  getPlanPopup() {
    const { t } = this.props;
    return (
      <div className="position-absolute share-tooltip-free">
        <img
          onClick={this.closePlanPopup}
          className="close"
          src={Close}
          alt=""
        />

        <h3>Upgrade your Plan</h3>

        <p style={{ letterSpacing: "0.15px" }}>
          You are currently on an {this.state.currentPlan} plan. To access the
          search data source feature, please upgrade to the Enterprise plan.
        </p>

        <Link to={ROUTES.PLAN_CHOOSE} type="a" target="_blank">
          <span className="actions color-3a79e6">
            See what you get when you upgrade
          </span>
        </Link>

        <div className="btn-box" style={{ marginTop: "40px" }}>
          <div className="content" style={{ justifyContent: "flex-end" }}>
            <span
              className="actions color-3a79e6"
              onClick={this.closePlanPopup}
            >
              {t("Maybe later")}
            </span>
            <span onClick={this.closePlanPopup} className="button">
              <Link
                to={ROUTES.PLAN_CHOOSE}
                style={{ textDecoration: "none", color: "white" }}
              >
                Upgrade
              </Link>
            </span>
          </div>
        </div>
      </div>
    );
  }

  closeQuotasPopUp() {
    const popupData = Object.assign({}, this.state.quotasPopupData);
    popupData.isUpdate = false;
    popupData.isEditProject = false;
    this.setState({
      quotasPopup: false,
      quotasPopupData: popupData,
    });
  }

  openQuotasPopup(quotasPopupData) {
    this.setState({
      quotasPopup: true,
      quotasPopupData: quotasPopupData,
    });
  }

  getQuotasPopUp() {
    const { t } = this.props;
    const { quotasPopupData, enterpriseFeatureIsDisabled } = this.state;

    const totalReviews = quotasPopupData.totalReviews;
    const maxReviews = quotasPopupData.maxReviews;
    const isUpdate = quotasPopupData.isUpdate;
    const isEditProject = quotasPopupData.isEditProject;
    const isAdmin = UserHelper.isAdmin();
    let exceedPercent = ((totalReviews - maxReviews) / maxReviews) * 100;
    exceedPercent = exceedPercent?.toFixed();

    return (
      <div className="position-absolute sensia-popup">
        <img
          onClick={this.closeQuotasPopUp}
          className="close"
          src={Close}
          alt=""
        />

        <Box sx={{ display: "flex", gap: "10px" }}>
          <ReviewsRoundedIcon sx={{ color: "#FF9378" }} />
          <STypography sx={{ fontSize: "18px" }}>
            {t("Storage Limit Exceed")}
          </STypography>
        </Box>

        <Box
          sx={{
            mt: "32px",
            display: "flex",
            alignItems: "center",
            gap: "28px",
          }}
        >
          <Box>
            <STypography sx={{ fontSize: "24px", fontWeight: 700 }}>
              <Trans i18nKey="hit_storage_media_limit_warning">
                You have exceeded your limit by {{ exceedPercent }}%
              </Trans>
            </STypography>

            <STypography
              sx={{
                mt: "15px",
                fontSize: "18px",
                fontWeight: 400,
                color: "#757575",
              }}
            >
              {isUpdate &&
                isAdmin &&
                t(
                  "To update your sources and continue benefiting from the latest updates, you need to increase your storage"
                )}
              {isUpdate &&
                !isAdmin &&
                t(
                  "Free up space to update your sources and continue benefiting from the latest updates."
                )}
              {isEditProject &&
                isAdmin &&
                t(
                  "To edit your project and continue benefiting from the latest updates, you need to increase your storage"
                )}
              {isEditProject &&
                !isAdmin &&
                t(
                  "Free up space to edit your project and continue benefiting from the latest updates."
                )}
              {!isUpdate &&
                !isEditProject &&
                isAdmin &&
                t(
                  "To add new data and continue receiving updates, you need to increase your storage"
                )}
              {!isUpdate &&
                !isEditProject &&
                !isAdmin &&
                t(
                  "Free up space to add new data and continue receiving updates and stay updated."
                )}
            </STypography>
          </Box>
          <img src={illus_quotas} alt="illustration-quotas" />
        </Box>

        {isAdmin && enterpriseFeatureIsDisabled && (
          <Button
            onClick={() =>
              this.props.history.push(ROUTES.SETTINGS_PLAN_AND_BILLING_OVERVIEW)
            }
            sx={{
              mt: "30px",
              borderRadius: "6px",
              background: "#F78B7C",
              color: "#FFF",
              textTransform: "none",
              "&:hover": {
                background: "#F78B7A",
              },
            }}
          >
            Add more Reviews
          </Button>
        )}
        {isAdmin && !enterpriseFeatureIsDisabled && (
          <Button
            onClick={() => IntercomHelper.trackEvent("contact-success-team")}
            sx={{
              mt: "30px",
              borderRadius: "6px",
              background: "#F78B7C",
              color: "#FFF",
              textTransform: "none",
              "&:hover": {
                background: "#F78B7A",
              },
            }}
          >
            Contact Our Success Team
          </Button>
        )}
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const { tabValue, project, print, datasourceList } = this.state;
    let page_title = this.pageTitleHandler();

    if (print) {
      this.print();
    }

    const roundSelectOther = [];

    if (datasourceList && datasourceList.length > 0) {
      roundSelectOther.push({
        label: t("Share"),
        picto: <img src={Share} alt="" />,
        action: () => {
          this.openSharePopup();
        },
      });

      const userIsSensia = UserHelper.userIsSensia();

      if (tabValue !== "overview") {
        roundSelectOther.push({
          label: t("Print"),
          picto: <img src={Print} alt="" />,
          action: () => {
            this.handlePrint();
          },
        });
      }

      if (project?.export_variable_available && tabValue === "insights") {
        roundSelectOther.push({
          label: t("Export Xlsx"),
          picto: <img src={ExportExcel} alt="variable exporter" />,
          action: () => {
            this.handleExportVariables();
          },
        });
      }

      if (tabValue === "inspect") {
        roundSelectOther.push({
          label: t("Export Xlsx"),
          picto: <img src={ExportExcel} alt="variable exporter" />,
          action: () => {
            this.handleExportInspectData();
          },
        });
      }

      if (tabValue === "insights") {
        roundSelectOther.push({
          label: t("Export Doc"),
          picto: <img src={Export} alt="" />,
          action: () => {
            this.handleExport();
          },
        });

        if (userIsSensia) {
          roundSelectOther.push({
            label: t("Export PPT"),
            picto: <img width="24px" height="24px" src={ExportPPT} alt="" />,
            action: () => {
              this.handleExportPPT();
            },
          });

          roundSelectOther.push({
            label: t("Export PDF"),
            picto: <img width="24px" height="24px" src={ExportPDF} alt="" />,
            action: () => {
              this.handleExportPDF();
            },
          });
        }
      }
    }

    const dateFilterDateLabel = DateHelper.getProjectDateFilterLabel(
      project?.filters?.date
    );

    const dateFilterTooltipTitle = DateHelper.getProjectDateFilterTooltipTitle(
      project?.filters?.date,
      project?.first_review_date,
      project?.last_review_date
    );

    return (
      <Fragment>
        <Header isProject={true} />
        <Helmet>
          <title>{page_title}</title>
        </Helmet>
        <Box
          sx={{
            position: "relative",
            maxWidth: "1100px",
            margin: "0 auto",
          }}
        >
          <div className="topTab responsive-920-padding-top-20 padding-bottom-20">
            {/* full width grey background */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: "50%",
                transform: "translateX(-50%)",
                width: "100vw",
                height: "100%",
                background: "#f8f8f8",
              }}
            />
            <div className="central-content">
              <div className="position-relative">
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontFamily: "Inter",
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "normal",
                    }}
                  >
                    {project?.name}
                  </Typography>
                  <RoundSelect
                    className="right"
                    disableHover={print}
                    lists={{
                      links: [
                        {
                          label: t("Add data source"),
                          className: "only-responsive-920",
                          action: () => {
                            this.props.history.push(
                              PROJECT_ADD_MEDIA(this.state.projectId)
                            );
                          },
                        },
                        {
                          label: t("Search data source"),
                          className: "only-responsive-920",
                          action: () => {
                            this.props.history.push(
                              PROJECT_SEARCH_MEDIA(this.state.projectId)
                            );
                          },
                        },
                        {
                          label: t("Edit project"),
                          icon: this.state.quotasExceeded ? (
                            <img src={upgradeIcon} />
                          ) : (
                            <></>
                          ),
                          action: () => {
                            if (this.state.quotasExceeded) {
                              this.setState({
                                quotasPopup: true,
                                quotasPopupData: {
                                  ...this.state.quotasPopupData,
                                  isEditProject: true,
                                },
                              });
                            } else {
                              this.props.history.push(
                                EDIT_PROJECT(this.state.projectId)
                              );
                            }
                          },
                        },
                        {
                          label: t("Manage classes"),
                          action: () => {
                            this.props.history.push(
                              PROJECT_EDIT(this.state.projectId)
                            );
                          },
                        },
                      ],
                      action: [
                        {
                          label: t("Archive project"),
                          withLoader: true,
                          action: (onThen) => {
                            this.onArchiveProject(onThen);
                          },
                        },
                        {
                          label: t("Delete project"),
                          action: () => {
                            this.onOpenDeleteProjectModal();
                          },
                        },
                      ],
                      other: roundSelectOther,
                    }}
                  />
                </Box>
              </div>
              <Box
                sx={{
                  display: "inline-flex", // S'assure que la Box s'ajuste à la taille de son contenu
                  position: "relative",
                  "&:hover .edit-button": {
                    display: "block",
                  },
                }}
              >
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing="10px"
                  sx={{
                    width: "fit-content",
                    mt: "2px",
                    pr: "10px",
                  }}
                >
                  {project?.type_name && (
                    <SChip
                      icon={
                        <img height="20px" src={star_icon_grey} alt="ia icon" />
                      }
                      label={project?.type_name}
                      variant="outlined"
                      sx={{
                        borderColor: "#9CA3AF",
                        backgroundColor: "#F9FAFB",
                        color: "#4B5563",
                      }}
                    />
                  )}
                  {project?.usecase?.is_enabled && (
                    <SChip
                      icon={
                        <InsightsIcon
                          style={{ color: "#4B5563", fontSize: "20px" }}
                        />
                      }
                      label={project?.usecase?.name}
                      variant="outlined"
                      sx={{
                        borderColor: "#9CA3AF",
                        backgroundColor: "#F9FAFB",
                        color: "#4B5563",
                      }}
                    />
                  )}
                  {project?.filters?.langs && (
                    <STooltip arrow title={project.filters.langs.join(", ")}>
                      <SChip
                        icon={
                          <LanguageIcon
                            style={{ color: "#3A79E6", fontSize: "20px" }}
                          />
                        }
                        label={StringHelper.arrayToString(
                          project.filters.langs
                        )}
                        variant="outlined"
                      />
                    </STooltip>
                  )}
                  {dateFilterDateLabel && (
                    <STooltip arrow title={dateFilterTooltipTitle}>
                      <SChip
                        icon={
                          <CalendarTodayOutlinedIcon
                            style={{ color: "#3A79E6", fontSize: "20px" }}
                          />
                        }
                        label={dateFilterDateLabel}
                        variant="outlined"
                        size="small"
                      />
                    </STooltip>
                  )}
                </Stack>
                <Button
                  onClick={() => {
                    if (this.state.quotasExceeded) {
                      this.setState({
                        quotasPopup: true,
                        quotasPopupData: {
                          ...this.state.quotasPopupData,
                          isEditProject: true,
                        },
                      });
                    } else {
                      this.props.history.push(
                        EDIT_PROJECT(this.state.projectId)
                      );
                    }
                  }}
                  className="edit-button"
                  size="small"
                  sx={{
                    textTransform: "none",
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)", // Centre verticalement
                    right: "-60px", // Ajuste la distance à droite du Stack
                    display: "none", // Caché par défaut
                  }}
                >
                  Edit
                </Button>
              </Box>
            </div>
          </div>
          <Box
            sx={{
              width: "100%",
              position: "sticky",
              top: "60px",
              zIndex: "18",
            }}
          >
            {/* full width grey background */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: "50%",
                transform: "translateX(-50%)",
                width: "100vw",
                height: "100%",
                background: "#f8f8f8",
              }}
            />
            <StyledTabs
              value={tabValue}
              onChange={this.handleChange}
              aria-label="project detail tabs"
            >
              <StyledTab value="overview" label="Overview" />
              <StyledTab value="insights" label="Insights" />
              <StyledTab value="in-depth" label="In-Depth" />
              <StyledTab value="inspect" label="Inspect" />
            </StyledTabs>
          </Box>
          <ProjectDataManager>
            {this.renderTabChildren(tabValue)}
          </ProjectDataManager>
        </Box>

        {this.state.planPopup && (
          <Fragment>
            <div id="fond"></div>
            {this.getPlanPopup()}
          </Fragment>
        )}
        {this.state.quotasPopup && (
          <Fragment>
            <div id="fond"></div>
            {this.getQuotasPopUp()}
          </Fragment>
        )}
        {this.state.deleteProject.display && (
          <DeleteModal
            itemValue={
              this.state.deleteProject.item
                ? this.state.deleteProject.item.name
                : null
            }
            title={t("Delete project")}
            subtitle={t("You are going to delete the project")}
            inputLabel={t("Enter project name to confirm")}
            buttonLabel={t("Delete project")}
            onClose={this.onCloseDeleteProjectModal}
            loading={this.state.deleteProject.loading}
            onDeleteItem={this.onDeleteProject}
          />
        )}
        {this.state.sharePopup && (
          <Fragment>
            <div id="fond"></div>
            <div>{this.getShareTooltipContent()}</div>
          </Fragment>
        )}
        {this.state.freePlanPopup && (
          <Fragment>
            <div id="fond"></div>
            {this.getFreePlanPopup()}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(ProjectView));
