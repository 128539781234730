import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  SelectInput,
  SearchInput,
  BooleanField,
  TopToolbar,
  DeleteButton,
  SimpleForm,
  TextInput,
  required,
  Create,
  Edit,
  TabbedForm,
  ReferenceManyField,
  ReferenceInput,
  AutocompleteInput,
  Button,
  Link,
  BooleanInput,
  useGetRecordId,
  useGetList,
  SelectArrayInput,
  ReferenceArrayInput,
  useNotify,
  useRedirect,
  useRecordContext,
  NumberInput,
  EditButton,
  ShowButton,
  ListButton,
  Show,
  SimpleShowLayout,
  useDataProvider,
} from "react-admin";

import AddIcon from "@mui/icons-material/Add";
import CloneIcon from "@mui/icons-material/FileCopy";
import { Box, Hidden, Typography, Chip } from "@mui/material";
import { getUrlParameters } from "./SectionList";
import { BackButton } from "./elements/CustomFields";


const QuickFilter = ({ label }) => {
  return <Chip sx={{ marginBottom: 1 }} label={label} />;
};


const ReportFilters = (props) => {
  var categoryFilterChoices = [];
  const { data, isLoading, error, refetch } = useGetList("categories");
  data?.map((category) => {
    categoryFilterChoices.push({ id: category.id, name: category.title });
  });

  var usecaseFilterChoices = [];
  const { data: usecases, isLoading: usecaseIsLoading, error: usecaseError, refetch: usecaseRefetch } = useGetList("usecases");
  usecases?.map((usecase) => {
    usecaseFilterChoices.push({ id: usecase.id, name: usecase.name });
  });

  return (
    <Filter {...props}>
      <SearchInput source="q" className="remove-line-height" alwaysOn />
      <SelectInput
        choices={categoryFilterChoices}
        label="Category"
        source="category"
        allowEmpty={false}
        isLoading={isLoading}
      />
      <SelectInput
        choices={usecaseFilterChoices}
        label="Use Case"
        source="usecase_id"
        allowEmpty={false}
        isLoading={isLoading}
      />      
      <SelectInput label="Type" source="scope_type" choices={[
        { id: 'PROJECT', name: 'PROJECT' },
        { id: 'DATA', name: 'DATA' },
      ]} />
      {/* <QuickFilter label="Visible to All" source="is_visible" defaultValue={true} />
      <QuickFilter label="Restricted" source="is_restricted" defaultValue={true} /> */}
    </Filter>
  );
};


export const CloneReportButton = () => {
  const dataProvider = useDataProvider();
  const report = useRecordContext();

  return (
    <>
      <Button
        label="Clone"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          // TypeScript knows the archive method
          dataProvider.cloneReport(report.id).then(() => {
            window.location.reload();
          })
        }}
      >
        <CloneIcon />
      </Button>
    </>
  );
};


const ReportShowButton = ({ record }) => [
  <EditButton label="Edit" record={record} />,
  <ShowButton label="Show" record={record} />,
  <CloneReportButton />,
];

export const ReportList = (props) => (
  <>
    <List filters={<ReportFilters />} {...props} perPage={25}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label="Report ID" source="id" />
        <TextField label="Type" source="scope_type" />
        <TextField label="Name" source="title" />
        <BooleanField
          label="Enabled"
          source="is_enabled"
          valueLabelTrue="active"
          valueLabelFalse="inactive"
        />
        <ReportShowButton />
      </Datagrid>
    </List>
  </>
);

const ReportShowActions = () => (
  <TopToolbar>
    <EditButton />
    {/* Add your custom actions */}
    <ListButton />
  </TopToolbar>
);

export const ReportShow = (props) => (
  <Show actions={<ReportShowActions />}>
    <SimpleShowLayout>
      <TextField label="Report ID" source="id" />
      <TextField label="Type" source="scope_type" />
      <TextField label="Name" source="title" />
      <BooleanField
        label="Enabled"
        source="is_enabled"
        valueLabelTrue="active"
        valueLabelFalse="inactive"
      />
    </SimpleShowLayout>
  </Show>
);

export const ReportCreate = () => (
  <Create resource="reports">
    <BackButton to={"/reports"} />
    <SimpleForm>
      <SelectInput
        source="scope_type"
        label="Type"
        validate={[required()]}
        choices={[
          { id: "PROJECT", name: "PROJECT" },
          { id: "DATA", name: "DATA" },
        ]}
      />
      <TextInput source="title" label="Name" validate={[required()]} />

    </SimpleForm>
  </Create>
);

const ReportEditActions = () => (
  <TopToolbar>
    <ShowButton />
    {/* Add your custom actions */}
    <ListButton />
  </TopToolbar>
);


export const ReportEdit = (props) => {
  const reportId = useGetRecordId();
  const sectionRowClick = (id, resource, record) =>
    `/sections/${id}/edit?reportId=${reportId}`;

  return (
    <Edit mutationMode="pessimistic" actions={<ReportEditActions />}>
      <BackButton to={"/reports"} />
      <TabbedForm>
        <TabbedForm.Tab label="General">
          <SelectInput
            source="scope_type"
            label="Type"
            validate={[required()]}
            choices={[
              { id: "PROJECT", name: "PROJECT" },
              { id: "DATA", name: "DATA" },
            ]}
          />
          <TextInput
            size="medium"
            source="title"
            label="Name"
            validate={[required()]}
            sx={{ width: "50%" }}
          />

          <BooleanInput
            label="Enabled"
            source="is_enabled"
            helperText="The report is enabled to all users"
          />

          <ReferenceArrayInput
            source="product_category_ids"
            reference="categories"
          >
            <SelectArrayInput optionText="title" />
          </ReferenceArrayInput>


        </TabbedForm.Tab>
        <TabbedForm.Tab label="Sections">
          <ReferenceManyField reference="sections" target="id" label="Sections">
            <Button
              component={Link}
              to={`/sections/create?reportId=${reportId}`}
              color="primary"
            >
              Create new section
            </Button>
            <Datagrid
              rowClick={sectionRowClick}
              sx={{ width: "100%" }}
              bulkActionButtons={false}
            >
              <TextField label="ID" source="id" sortable={false} />
              <TextField label="Title" source="title" sortable={false} />
              <TextField
                label="Max Tokens"
                source="max_tokens"
                sortable={false}
              />
              <TextField
                label="Order"
                source="display_order"
                sortable={false}
              />
              <TextField label="Prompt" source="prompt" sortable={false} />
            </Datagrid>
          </ReferenceManyField>
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
};



