import React, { Fragment } from "react";

import "./Score.css";
import StringHelper from "../../app/helpers/StringHelper";
import EmotionHelper from "../../app/helpers/EmotionHelper";

import image from "../../res/images/icons/last-review.svg";
import interrogationGreyIcon from "../../res/images/icons/interrogationgrey.svg";
import ProjectRoundedEmotion from "../../views/projectWorkspace/elements/ProjectRoundedEmotion";
import moment from "moment";
import { Box } from "@mui/material";

class Score extends React.Component {
  constructor(props) {
    super(props);
  }

  showEmotion() {
    switch (typeof this.props.feeling) {
      case "string":
        return (
          <div>
            <div
              id="color-dot"
              style={{
                backgroundColor: EmotionHelper.getEmotionColor(
                  this.props.feeling
                ),
                width: "8px",
                height: "8px",
                borderRadius: "100%",
              }}
            />
            <h3 className={this.props?.size} id="feeling">
              {this.props.feeling ? (
                StringHelper.capitalizeFirstLetter(this.props.feeling)
              ) : (
                <span style={{ fontSize: "24px" }}>{"-"}</span>
              )}
            </h3>
          </div>
        );

      case "object":
        // check if null (because typeof null return object)
        if (this.props.feeling === null) {
          return (
            <h3
              style={{ fontSize: "24px" }}
              className={this.props?.size}
              id="feeling"
            >
              {"-"}
            </h3>
          );
        }
        return (
          <ProjectRoundedEmotion
            allEmotions={this.props.feeling}
            withTooltip={true}
            projectId={this.props.projectId}
            datasourceId={this.props.datasourceId}
            inScore={true}
          />
        );

      case "undefined":
        return (
          <h3
            style={{ fontSize: "24px" }}
            className={this.props?.size}
            id="feeling"
          >
            {"-"}
          </h3>
        );

      default:
        return (
          <h3
            style={{ fontSize: "24px" }}
            className={this.props?.size}
            id="feeling"
          >
            {"-"}
          </h3>
        );
    }
  }

  render() {
    const { style, t, sourceTooltip, classScore = false } = this.props;
    return (
      <div className={this.props?.size} id="scores" style={style}>
        {/* sources */}
        {!this.props?.listItem && !classScore && (
          <div id="sources" className="number-box">
            <p className={this.props?.size}>{`Source${
              this.props.sources > 1 ? "s" : ""
            }`}</p>
            <h3 className={this.props?.size}>{this.props.sources || "-"}</h3>

            <div id="source-tooltip">
              <div id="cachetop"></div>
              {sourceTooltip !== undefined && (
                <div className="content">
                  {sourceTooltip.map((elm, i) => {
                    const lang = [];
                    elm.languages.forEach((langItem) => {
                      lang.push(langItem.label);
                    });
                    return (
                      <div className="box" key={i}>
                        <div className="item">
                          <img
                            src={
                              process.env.REACT_APP_API_URL +
                              elm.datasource_type[0]?.icon
                            }
                            alt="datasource's logo"
                            height="22px"
                          />
                          <div className="item-text">
                            <p className="title">{elm.title}</p>
                            <p>{lang.join(", ")}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              <div id="cachebottom"></div>
            </div>
          </div>
        )}
        {!this.props?.listItem && !classScore && (
          <div className="separator"></div>
        )}

        {/* reviews */}
        <div id="total-reviews" className="number-box" style={{ width: "" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "2px",
            }}
          >
            <p className={this.props?.size}>Reviews</p>
            {/* <img
              src={interrogationGreyIcon}
              alt="tooltip review"
              height="12px"
            /> */}
          </Box>
          <h3 className={this.props?.size}>
            {this.props.total_reviews || "-"}
          </h3>
          <div id="reviews-tooltip">
            <div id="cachetop"></div>
            <div className="content">
              <div className="top-infos">
                <div className="review-title">
                  <h3>{this.props.total_reviews || "0"}</h3>
                  <p>out of {this.props.total || "0"}</p>
                </div>
              </div>
              <div className="progress-bar">
                <div
                  className="progress-bar-content"
                  style={{ width: `${this.props.percent}` }}
                ></div>
              </div>
              <p>Included/Total</p>
            </div>
            <div id="cachebottom"></div>
          </div>
        </div>

        {/* relevant reviews */}
        {!classScore && (
          <div id="reviews" className="number-box" style={{ width: "115px" }}>
            <p className={this.props?.size}>Relevant reviews</p>
            <h3 className={this.props?.size}>
              {this.props.relevantReviews || "-"}{" "}
              {this.props.relevantReviews === "-" ? (
                ""
              ) : (
                <div className={`${this.props?.size} progress-bar`}>
                  <div
                    className="progress-bar-content"
                    style={{ width: `${this.props.relevantReviews}` }}
                  ></div>
                </div>
              )}
            </h3>

            <div id="relevant-tooltip">
              <div id="cachetop"></div>
              <div className="content">
                <div className="top-infos">
                  <div className="relevant-title">
                    <h3 className={this.props?.size}>
                      {this.props.reviews || "0"}
                    </h3>
                    <p>out of {this.props.total_reviews || "-"}</p>
                  </div>
                  <h3 className={this.props?.size}>
                    {this.props.relevantReviews || "-"}
                  </h3>
                </div>
                <div className="progress-bar">
                  <div
                    className="progress-bar-content"
                    style={{ width: `${this.props.relevantReviews}` }}
                  ></div>
                </div>
                <p>relevants reviews</p>
                <div className="last-review">
                  {this.props.last_review_date !== null && (
                    <Fragment>
                      <img src={image} alt="" />
                      <p>
                        Last review:{" "}
                        {moment
                          .utc(this.props.last_review_date)
                          .local()
                          .fromNow(true)}
                      </p>
                    </Fragment>
                  )}
                </div>
              </div>
              <div id="cachebottom"></div>
            </div>
          </div>
        )}

        <div className="separator"></div>

        {/* positive */}
        <div className="number-box" style={{ width: "55px" }}>
          <p className={this.props?.size}>Positive</p>
          <h3 className={`positive ${this.props?.size}`}>
            {this.props.positive || "-"}
          </h3>
        </div>

        {/* negative */}
        <div className="number-box" style={{ width: "55px" }}>
          <p className={this.props?.size}>Negative</p>
          <h3 className={`negative ${this.props?.size}`}>
            {this.props.negative || "-"}
          </h3>
        </div>

        <div className="separator"></div>

        {/* score */}
        <div className="number-box" style={{ width: "55px" }}>
          <p className={this.props?.size}>Score</p>
          <h3 className={this.props?.size}>
            {typeof this.props.score !== "number"
              ? "-"
              : this.props.score === "-"
              ? "-"
              : Math.round(this.props.score * 100) / 100}
          </h3>
        </div>

        <div className="separator"></div>

        {/* top feeling */}
        <div className="feeling-box feeling">
          <p className={this.props?.size}>Top feeling</p>
          {this.showEmotion()}
        </div>
      </div>
    );
  }
}

export default Score;
