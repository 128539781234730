import React, { Fragment } from "react";
import "./tooltip.css";
import { withTranslation } from "react-i18next";
import Loader from "../loader/Loader";
import ColoredScore from "../ui/ColoredScore";
import ReportHelper from "../../app/helpers/ReportHelper";
import RoundedEmotion from "../ui/RoundedEmotion";
import { projectService } from "../../app/services/project.service";
import StringHelper from "../../app/helpers/StringHelper";
import Tooltip from "./Tooltip";
import PercentToolTipContent from "./PercentToolTipContent";

class KeywordTooltipContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      followLoading: false,
      item: props.item,
    };

    this.getFollowButtonContent = this.getFollowButtonContent.bind(this);
    this.handleFollowOnClick = this.handleFollowOnClick.bind(this);
    this.getClassLibelle = this.getClassLibelle.bind(this);
    this.getScoreClassAndOccurence = this.getScoreClassAndOccurence.bind(this);
    this.getPercent = this.getPercent.bind(this);
    this.getPercentTooltipContent = this.getPercentTooltipContent.bind(this);
  }

  handleFollowOnClick(event) {
    event.stopPropagation();
    this.setState({
      followLoading: true,
    });
    const { item } = this.props;
    const theKeyword = item && item.keyword ? item.keyword : this.props.keyword;
    projectService.post.followKeyword(
      this.props.projectId,
      {
        keyword: theKeyword,
        is_follow: !this.state.item.is_follow,
      },
      (response) => {
        let newItem = Object.assign({}, this.state.item);
        newItem.is_follow = !this.state.item.is_follow;
        this.setState({
          followLoading: false,
          item: newItem,
        });
        if (this.props.onAfterFollow) {
          this.props.onAfterFollow();
        }
      },
      (error) => {
        this.setState({
          followLoading: false,
        });
        if (this.props.onAfterFollow) {
          this.props.onAfterFollow();
        }
      }
    );
  }

  getPercent(value, percent, total_obs) {
    if (percent !== null) {
      return (
        <Tooltip
          content={this.getPercentTooltipContent(value, percent, total_obs)}
        >
          {percent}
        </Tooltip>
      );
    }
    return "-";
  }

  getPercentTooltipContent(value, percent, total_obs) {
    return (
      <div className="base-tooltip">
        <PercentToolTipContent
          percent={value}
          percentValue={percent}
          totalObs={total_obs}
        />
      </div>
    );
  }

  getFollowButtonContent() {
    const { t } = this.props;
    const { item } = this.state;
    if (this.state.followLoading) {
      return (
        <span className="text-align-left">
          <Loader className="size-20" />
        </span>
      );
    }
    if (item.is_follow) {
      return (
        <span
          className="link-multi-state font-size-15"
          onClick={this.handleFollowOnClick}
        >
          <span className="initial color-564c81">
            <span className="mini-round-icon following margin-right-5" />
            <span className="display-inline-block vertical-align-middle">
              {t("Following")}
            </span>
          </span>
          <span className="on-hover color-e04f77">
            <span className="mini-round-icon unfollow margin-right-5">
              <span />
              <span />
            </span>
            <span className="display-inline-block vertical-align-middle">
              {t("Unfollow")}
            </span>
          </span>
        </span>
      );
    }
    return (
      <span
        className="link-blue font-size-15"
        onClick={this.handleFollowOnClick}
      >
        {t("Follow attribute")}
      </span>
    );
  }

  getClassLibelle() {
    const { t } = this.props;
    const { item } = this.state;
    let classes = [];
    if (item.hasOwnProperty("class") && item.class.length > 0) {
      item.class.forEach((element) => {
        if (element.id != ReportHelper.CLASS_IDS.UNKNOWN) {
          classes.push(element);
        }
      });
    }
    let classeLibelle = t("Add class…");
    if (classes.length > 0) {
      classeLibelle = classes[0].display_name;
      if (classes.length > 1) {
        classeLibelle += " ";
        classeLibelle +=
          classes.length > 2
            ? t("and {{count}} others", { count: classes.length - 1 })
            : t("and {{count}} other", { count: classes.length - 1 });
      }
    }
    return classeLibelle;
  }

  getScoreClassAndOccurence(item, scoreClass, occurences) {
    const { t } = this.props;
    let theScore = "-";

    if (item) {
      theScore = item.average_score ? item.average_score : item.score;
      if (theScore != null) {
        theScore = parseFloat(theScore.toFixed(2));
      } else {
        theScore = "-";
      }
      scoreClass = ReportHelper.getScoreClass(theScore);
    }
    let nbOccurences = "";
    let scoreClassNames = ["font-inter-600", scoreClass];
    if (item && item.isGlobalScore) {
      nbOccurences =
        occurences > 1
          ? t("{{count}} Occurences", { count: occurences })
          : t("{{count}} Occurence", { count: occurences });
      scoreClassNames.push("font-size-36");
    } else {
      nbOccurences =
        occurences > 1
          ? t("{{count}} Occurences", { count: occurences })
          : t("{{count}} Occurence", { count: occurences });
      scoreClassNames.push("font-size-22");
    }
    return { nbOccurences, scoreClassNames, theScore };
  }

  render() {
    const { t, withSeparator } = this.props;
    const { item } = this.state;
    const occurences = item ? item.occurence : 0;
    let scoreClass = "color-d2d2d2";

    const { nbOccurences, scoreClassNames, theScore } =
      this.getScoreClassAndOccurence(item, scoreClass, occurences);
    const classeLibelle = this.getClassLibelle();
    const theKeyword = item && item.keyword ? item.keyword : this.props.keyword;
    const withFollowButton =
      item.canFollow || (!item.canFollow && item.is_follow);
    let positivePercent = "-";
    let negativePercent = "-";

    if (item) {
      if (item.percent_pos != null) {
        positivePercent = this.getPercent(
          "positive",
          Number(item.percent_pos).toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 0,
          }),
          item.total
        );
      }
      if (item.percent_neg != null) {
        negativePercent = this.getPercent(
          "negative",
          Number(item.percent_neg).toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 0,
          }),
          item.total
        );
      }
    }
    return (
      <Fragment>
        {item && (
          <div className="content">
            <div className="font-size-18 font-inter-600 padding-bottom-2">
              {StringHelper.capitalize(theKeyword)}
            </div>
            <div className="font-size-13 color-7f7f7f">{nbOccurences}</div>
            <div className="padding-bottom-10">
              <span
                className="link-blue font-size-13"
                onClick={() => {
                  if (
                    typeof this.props.onOpenUpdateKeywordClassModal ==
                    "function"
                  ) {
                    this.props.onOpenUpdateKeywordClassModal(item);
                  }
                }}
              >
                {classeLibelle}
              </span>
            </div>
            <div className="content-column">
              <div className="column-percent-45 padding-right-5">
                <div className={scoreClassNames.join(" ")}>
                  {item ? <ColoredScore score={theScore} /> : "-"}
                </div>
                <div className="font-size-13 color-7f7f7f">
                  {t("Average score")}
                </div>
              </div>
              <div className="column-percent-50 padding-left-5">
                <div>
                  <RoundedEmotion emotion={item.emotion} />
                </div>
                <div className="font-size-13 color-7f7f7f padding-top-5">
                  {t("Top feeling")}
                </div>
              </div>
            </div>
            <div className="content-column">
              <div className="column-percent-45 padding-right-5">
                <div className="positive-percent-keyword-tooltip">
                  {positivePercent}
                </div>
                <div className="font-size-13 color-7f7f7f padding-top-5">
                  {t("Positive")}
                </div>
              </div>
              <div className="column-percent-50 padding-left-5">
                <div className="negative-percent-keyword-tooltip">
                  {negativePercent}
                </div>
                <div className="font-size-13 color-7f7f7f padding-top-5">
                  {t("Negative")}
                </div>
              </div>
            </div>
          </div>
        )}
        {withFollowButton && (item != null || withSeparator) && (
          <div className="tooltip-separator" />
        )}
        {withFollowButton && (
          <div className="content-tooltip-action-follow">
            {this.getFollowButtonContent()}
          </div>
        )}
      </Fragment>
    );
  }
}

KeywordTooltipContent.defaultProps = {
  withFollowButton: true,
};

export default withTranslation()(KeywordTooltipContent);
